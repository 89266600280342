<template>
	<v-dialog
    v-model="dialogBonos.estatus"
    hide-overlay
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title primary-title class="text-subtitle-1">
        BONO TEACHERS
      </v-card-title>
      <v-card-text >

      	<v-row justify="end">
	      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
	          <v-text-field
	            v-model="search"
	            dense
	            filled
	            single-line
	            label="Buscar"
	            append-icon="mdi-magnify"
	          ></v-text-field>  
	        </v-col>
      	</v-row>

        <v-data-table
          :headers="headersCerrados"
          :items="teachersBonos"
          class="elevation-0"
          dense
          :search="search"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
        	color="black"
        	dark 
        	tile
        	@click="dialogBonos.estatus = false"
        >Cancelar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogBonos',
			'prospectosCerrados',
			'gruposRoles',
			'teachers'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      teachersBonos: [],

      headersCerrados: [
        { text: 'id',           value: 'id_teacher'  },
        { text: 'Teacher',      value: 'teacher'     },  
        { text: 'Categoría',    value: 'categoria'   },  
        { text: 'Bono',         value: 'bono'        },  
        { text: 'Bono Luis',    value: 'bono_l'      },  
        { text: 'eliminable',   value: 'eliminable'  },  
      ],
      search:'',
      curso: null,

    }),

    created(){
    	let gruposActivos = this.gruposRoles.filter( el => { return el.deleted == 0 && !el.grupo.match('ONLINE') })


			// Ordenar el arreglo de eventos por la hora de inicio
			gruposActivos.sort(this.compararHorasInicio);

    	// VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
    	// Vamos a sacar los teachers de cada bloque
      let sa1 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
			let sa2 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
			let sa3 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
			let sa4 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

    	// Vamos a sacar los teachers de cada bloque
      let sa11 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
			let sa22 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
			let sa33 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
			let sa44 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})


      let sabadoCompleto = sa1.concat( sa2 ).concat( sa3 ).concat( sa4 ).concat( sa11 ).concat( sa22 ).concat( sa33 ).concat( sa44 )
      let sabadoTeacherDup  = sa1.concat( sa11 )

      let sabadoTeacher = Array.from(new Set(sabadoTeacherDup.map(JSON.stringify))).map(JSON.parse);

      // Conteo

      for( const i in sabadoTeacher ){

      	const { id_teacher } = sabadoTeacher[i]

      	sabadoTeacher[i]['conteo'] = sabadoCompleto.filter( el => el.id_teacher == id_teacher ).length

      }

      sabadoTeacher = sabadoTeacher.filter( el => el.conteo >= 4 )

      let teachersBonoSabatino = []

      for( const i in sabadoTeacher ){

      	const { id_teacher, teacher } = sabadoTeacher[i]

      	let conteoNoRetardos = 0

      	let valida1 = gruposActivos.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${1}`] == id_teacher })
      	let valida2 = gruposActivos.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${2}`] == id_teacher })
      	let valida3 = gruposActivos.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${3}`] == id_teacher })
      	let valida4 = gruposActivos.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${4}`] == id_teacher })

      	// Validamos las horas 
      	if( valida1 ){
      		const { hora_inicio, lu1_hora_asistencia } = valida1
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida2 ){
      		const { hora_inicio, lu2_hora_asistencia } = valida1
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida3 ){
      		const { hora_inicio, lu3_hora_asistencia } = valida1
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida4 ){
      		const { hora_inicio, lu4_hora_asistencia } = valida1
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	sabadoTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

      } 

      sabadoTeacher = sabadoTeacher.filter( el => el.conteoNoRetardos >= 3 )

      // *************************************************************************************
      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
    	// Vamos a sacar los teachers de cada bloque
      let do1 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
			let do2 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
			let do3 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
			let do4 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

    	// Vamos a sacar los teachers de cada bloque
      let do11 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
			let do22 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
			let do33 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
			let do44 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})


      let domingoCompleto = do1.concat( do2 ).concat( do3 ).concat( do4 ).concat( do11 ).concat( do22 ).concat( do33 ).concat( do44 )
      let domingoTeacherDup  = do1.concat( do11 )

      let domingoTeacher = Array.from(new Set(domingoTeacherDup.map(JSON.stringify))).map(JSON.parse);

      // Conteo

      for( const i in domingoTeacher ){

      	const { id_teacher } = domingoTeacher[i]

      	domingoTeacher[i]['conteo'] = domingoCompleto.filter( el => el.id_teacher == id_teacher ).length

      }

      domingoTeacher = domingoTeacher.filter( el => el.conteo >= 4 )

      for( const i in domingoTeacher ){

      	const { id_teacher, teacher } = domingoTeacher[i]

      	let conteoNoRetardos = 0

      	let valida1 = gruposActivos.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${1}`] == id_teacher ) })
      	let valida2 = gruposActivos.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${2}`] == id_teacher ) })
      	let valida3 = gruposActivos.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${3}`] == id_teacher ) })
      	let valida4 = gruposActivos.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${4}`] == id_teacher ) })

      	// Validamos las horas 
      	if( valida1 ){
      		const { hora_inicio, lu1_hora_asistencia } = valida1
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida2 ){
      		const { hora_inicio, lu2_hora_asistencia } = valida2
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida3 ){
      		const { hora_inicio, lu3_hora_asistencia } = valida3 
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida4 ){
      		const { hora_inicio, lu4_hora_asistencia } = valida4 
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	domingoTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

      } 

      domingoTeacher = domingoTeacher.filter( el => el.conteoNoRetardos >= 3 )

      // *************************************************************************************
      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
    	// Vamos a sacar los teachers de cada bloque
      let lu1 = gruposActivos.filter( el => { return ( el.lunes ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
			let lu2 = gruposActivos.filter( el => { return ( el.lunes ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
			let lu3 = gruposActivos.filter( el => { return ( el.lunes ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
			let lu4 = gruposActivos.filter( el => { return ( el.lunes ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

      let lunesCompleto = lu1.concat( lu2 ).concat( lu3 ).concat( lu4 )
      let lunesTeacherDup  = lu1

      let lunesTeacher = Array.from(new Set(lunesTeacherDup.map(JSON.stringify))).map(JSON.parse);

      // Conteo

      for( const i in lunesTeacher ){

      	const { id_teacher } = lunesTeacher[i]

      	lunesTeacher[i]['conteo'] = lunesCompleto.filter( el => el.id_teacher == id_teacher ).length

      }

      lunesTeacher = lunesTeacher.filter( el => el.conteo >= 3 )

      for( const i in lunesTeacher ){

      	const { id_teacher, teacher } = lunesTeacher[i]

      	let conteoNoRetardos = 0

      	let valida1 = gruposActivos.find( el => { return ( el.lunes ) && el[`lu${1}`] == id_teacher })
      	let valida2 = gruposActivos.find( el => { return ( el.lunes ) && el[`lu${2}`] == id_teacher })
      	let valida3 = gruposActivos.find( el => { return ( el.lunes ) && el[`lu${3}`] == id_teacher })
      	let valida4 = gruposActivos.find( el => { return ( el.lunes ) && el[`lu${4}`] == id_teacher })

      	// Validamos las horas 
      	if( valida1 ){
      		const { hora_inicio, lu1_hora_asistencia } = valida1
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida2 ){
      		const { hora_inicio, lu2_hora_asistencia } = valida2
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida3 ){
      		const { hora_inicio, lu3_hora_asistencia } = valida3
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida4 ){
      		const { hora_inicio, lu4_hora_asistencia } = valida4
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	lunesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

      } 

      lunesTeacher = lunesTeacher.filter( el => el.conteoNoRetardos >= 3 )


      // *************************************************************************************
      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
    	// Vamos a sacar los teachers de cada bloque
      let ma1 = gruposActivos.filter( el => { return ( el.martes ) && el[`ma${1}_n`] && el[`ma${1}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ma${1}`], teacher: registro[`ma${1}_n`]  }})
			let ma2 = gruposActivos.filter( el => { return ( el.martes ) && el[`ma${2}_n`] && el[`ma${2}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ma${2}`], teacher: registro[`ma${2}_n`]  }})
			let ma3 = gruposActivos.filter( el => { return ( el.martes ) && el[`ma${3}_n`] && el[`ma${3}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ma${3}`], teacher: registro[`ma${3}_n`]  }})
			let ma4 = gruposActivos.filter( el => { return ( el.martes ) && el[`ma${4}_n`] && el[`ma${4}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ma${4}`], teacher: registro[`ma${4}_n`]  }})

      let martesCompleto = ma1.concat( ma2 ).concat( ma3 ).concat( ma4 )
      let martesTeacherDup  = ma1

      let martesTeacher = Array.from(new Set(martesTeacherDup.map(JSON.stringify))).map(JSON.parse);

      // Conteo

      for( const i in martesTeacher ){

      	const { id_teacher } = martesTeacher[i]

      	martesTeacher[i]['conteo'] = martesCompleto.filter( el => el.id_teacher == id_teacher ).length

      }

      martesTeacher = martesTeacher.filter( el => el.conteo >= 4 )

      for( const i in martesTeacher ){

      	const { id_teacher, teacher } = martesTeacher[i]

      	let conteoNoRetardos = 0

      	let valida1 = gruposActivos.find( el => { return ( el.martes ) && el[`ma${1}`] == id_teacher })
      	let valida2 = gruposActivos.find( el => { return ( el.martes ) && el[`ma${2}`] == id_teacher })
      	let valida3 = gruposActivos.find( el => { return ( el.martes ) && el[`ma${3}`] == id_teacher })
      	let valida4 = gruposActivos.find( el => { return ( el.martes ) && el[`ma${4}`] == id_teacher })

      	// Validamos las horas 
      	if( valida1 ){
      		const { hora_inicio, ma1_hora_asistencia } = valida1
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma1_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida2 ){
      		const { hora_inicio, ma2_hora_asistencia } = valida2
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma2_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida3 ){
      		const { hora_inicio, ma3_hora_asistencia } = valida3
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma3_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida4 ){
      		const { hora_inicio, ma4_hora_asistencia } = valida4
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma4_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	martesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

      } 

      martesTeacher = martesTeacher.filter( el => el.conteoNoRetardos >= 3 )


      // *************************************************************************************
      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
    	// Vamos a sacar los teachers de cada bloque
      let mi1 = gruposActivos.filter( el => { return ( el.miercoles ) && el[`mi${1}_n`] && el[`mi${1}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`mi${1}`], teacher: registro[`mi${1}_n`]  }})
			let mi2 = gruposActivos.filter( el => { return ( el.miercoles ) && el[`mi${2}_n`] && el[`mi${2}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`mi${2}`], teacher: registro[`mi${2}_n`]  }})
			let mi3 = gruposActivos.filter( el => { return ( el.miercoles ) && el[`mi${3}_n`] && el[`mi${3}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`mi${3}`], teacher: registro[`mi${3}_n`]  }})
			let mi4 = gruposActivos.filter( el => { return ( el.miercoles ) && el[`mi${4}_n`] && el[`mi${4}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`mi${4}`], teacher: registro[`mi${4}_n`]  }})

      let miercolesCompleto = mi1.concat( mi2 ).concat( mi3 ).concat( mi4 )
      let miercolesTeacherDup  = mi1

      let miercolesTeacher = Array.from(new Set(miercolesTeacherDup.map(JSON.stringify))).map(JSON.parse);

      // Conteo

      for( const i in miercolesTeacher ){

      	const { id_teacher } = miercolesTeacher[i]

      	miercolesTeacher[i]['conteo'] = miercolesCompleto.filter( el => el.id_teacher == id_teacher ).length

      }

      miercolesTeacher = miercolesTeacher.filter( el => el.conteo >= 4 )

      for( const i in miercolesTeacher ){

      	const { id_teacher, teacher } = miercolesTeacher[i]

      	let conteoNoRetardos = 0

      	let valida1 = gruposActivos.find( el => { return ( el.miercoles ) && el[`mi${1}`] == id_teacher })
      	let valida2 = gruposActivos.find( el => { return ( el.miercoles ) && el[`mi${2}`] == id_teacher })
      	let valida3 = gruposActivos.find( el => { return ( el.miercoles ) && el[`mi${3}`] == id_teacher })
      	let valida4 = gruposActivos.find( el => { return ( el.miercoles ) && el[`mi${4}`] == id_teacher })

      	// Validamos las horas 
      	if( valida1 ){
      		const { hora_inicio, mi1_hora_asistencia } = valida1
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi1_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida2 ){
      		const { hora_inicio, mi2_hora_asistencia } = valida2
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi2_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida3 ){
      		const { hora_inicio, mi3_hora_asistencia } = valida3
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi3_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida4 ){
      		const { hora_inicio, mi4_hora_asistencia } = valida4
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi4_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	miercolesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

      } 

      miercolesTeacher = miercolesTeacher.filter( el => el.conteoNoRetardos >= 3 )


      // *************************************************************************************
      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
    	// Vamos a sacar los teachers de cada bloque
      let ju1 = gruposActivos.filter( el => { return ( el.jueves ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
			let ju2 = gruposActivos.filter( el => { return ( el.jueves ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
			let ju3 = gruposActivos.filter( el => { return ( el.jueves ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
			let ju4 = gruposActivos.filter( el => { return ( el.jueves ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})

      let juevesCompleto = ju1.concat( ju2 ).concat( ju3 ).concat( ju4 )
      let juevesTeacherDup  = ju1

      let juevesTeacher = Array.from(new Set(juevesTeacherDup.map(JSON.stringify))).map(JSON.parse);

      // Conteo

      for( const i in juevesTeacher ){

      	const { id_teacher } = juevesTeacher[i]

      	juevesTeacher[i]['conteo'] = juevesCompleto.filter( el => el.id_teacher == id_teacher ).length

      }

      juevesTeacher = juevesTeacher.filter( el => el.conteo >= 4 )

      for( const i in juevesTeacher ){

      	const { id_teacher, teacher } = juevesTeacher[i]

      	let conteoNoRetardos = 0

      	let valida1 = gruposActivos.find( el => { return ( el.jueves ) && el[`ju${1}`] == id_teacher })
      	let valida2 = gruposActivos.find( el => { return ( el.jueves ) && el[`ju${2}`] == id_teacher })
      	let valida3 = gruposActivos.find( el => { return ( el.jueves ) && el[`ju${3}`] == id_teacher })
      	let valida4 = gruposActivos.find( el => { return ( el.jueves ) && el[`ju${4}`] == id_teacher })

      	// Validamos las horas 
      	if( valida1 ){
      		const { hora_inicio, ju1_hora_asistencia } = valida1
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju1_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida2 ){
      		const { hora_inicio, ju2_hora_asistencia } = valida2
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju2_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida3 ){
      		const { hora_inicio, ju3_hora_asistencia } = valida3
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju3_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida4 ){
      		const { hora_inicio, ju4_hora_asistencia } = valida4
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju4_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	juevesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

      } 

      juevesTeacher = juevesTeacher.filter( el => el.conteoNoRetardos >= 3 )


      // *************************************************************************************
      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
    	// Vamos a sacar los teachers de cada bloque
      let vi1 = gruposActivos.filter( el => { return ( el.viernes ) && el[`vi${1}_n`] && el[`vi${1}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`vi${1}`], teacher: registro[`vi${1}_n`]  }})
			let vi2 = gruposActivos.filter( el => { return ( el.viernes ) && el[`vi${2}_n`] && el[`vi${2}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`vi${2}`], teacher: registro[`vi${2}_n`]  }})
			let vi3 = gruposActivos.filter( el => { return ( el.viernes ) && el[`vi${3}_n`] && el[`vi${3}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`vi${3}`], teacher: registro[`vi${3}_n`]  }})
			let vi4 = gruposActivos.filter( el => { return ( el.viernes ) && el[`vi${4}_n`] && el[`vi${4}_hora_asistencia`] })
    							.map((registro) => { return { id_teacher: registro[`vi${4}`], teacher: registro[`vi${4}_n`]  }})

      let viernesCompleto = vi1.concat( vi2 ).concat( vi3 ).concat( vi4 )
      let viernesTeacherDup  = vi1

      let viernesTeacher = Array.from(new Set(viernesTeacherDup.map(JSON.stringify))).map(JSON.parse);

      // Conteo

      for( const i in viernesTeacher ){

      	const { id_teacher } = viernesTeacher[i]

      	viernesTeacher[i]['conteo'] = viernesCompleto.filter( el => el.id_teacher == id_teacher ).length

      }

      viernesTeacher = viernesTeacher.filter( el => el.conteo >= 4 )

      for( const i in viernesTeacher ){

      	const { id_teacher, teacher } = viernesTeacher[i]

      	let conteoNoRetardos = 0

      	let valida1 = gruposActivos.find( el => { return ( el.viernes ) && el[`vi${1}`] == id_teacher })
      	let valida2 = gruposActivos.find( el => { return ( el.viernes ) && el[`vi${2}`] == id_teacher })
      	let valida3 = gruposActivos.find( el => { return ( el.viernes ) && el[`vi${3}`] == id_teacher })
      	let valida4 = gruposActivos.find( el => { return ( el.viernes ) && el[`vi${4}`] == id_teacher })

      	// Validamos las horas 
      	if( valida1 ){
      		const { hora_inicio, ju1_hora_asistencia } = valida1
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju1_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida2 ){
      		const { hora_inicio, ju2_hora_asistencia } = valida2
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju2_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida3 ){
      		const { hora_inicio, ju3_hora_asistencia } = valida3
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju3_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	// Validamos las horas 
      	if( valida4 ){
      		const { hora_inicio, ju4_hora_asistencia } = valida4
      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju4_hora_asistencia.substr(11,5) ) ? 1 : 0
      	}

      	viernesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

      } 

      viernesTeacher = viernesTeacher.filter( el => el.conteoNoRetardos >= 3 )

      let allTeachersDu = lunesTeacher.concat( martesTeacher).concat( juevesTeacher ).concat( miercolesTeacher ).concat( viernesTeacher ).concat( sabadoTeacher ).concat( domingoTeacher )

      // Utilizar un conjunto para mantener un registro de los id_teacher únicos
	    var idTeacherUnicos = new Set();
	    // Utilizar filter para filtrar los elementos del arreglo
	    // y mantener solo aquellos con id_teacher que no estén en el conjunto
	    this.teachersBonos = allTeachersDu.filter(function (elemento) {
	        // Si el id_teacher ya está en el conjunto, retorna false (no lo incluye)
	        // Si no está en el conjunto, lo agrega y retorna true (lo incluye)
	        if (idTeacherUnicos.has(elemento.id_teacher)) {
	          return false;
	        } else {
	          idTeacherUnicos.add(elemento.id_teacher);
	          return true;
	        }
	    });

	    // Ahora... hay que ponerle qué días, estára....
	    for( const i in this.teachersBonos ){

	    	const { id_teacher, teacher } = this.teachersBonos[i]


	    	this.teachersBonos[i]['eliminable'] = 0
	    	this.teachersBonos[i]['bono']       = 0
	    	this.teachersBonos[i]['bono_l']     = 0

	    	const existeSabado    = sabadoTeacher.find( el => el.id_teacher == id_teacher )
	    	const existeDomingo   = domingoTeacher.find( el => el.id_teacher == id_teacher )
	    	const existeLunes     = lunesTeacher.find( el => el.id_teacher == id_teacher )
	    	const existeMartes    = martesTeacher.find( el => el.id_teacher == id_teacher )
	    	const existeMiercoles = juevesTeacher.find( el => el.id_teacher == id_teacher )
	    	const existeJueves    = miercolesTeacher.find( el => el.id_teacher == id_teacher )
	    	const existeViernes   = viernesTeacher.find( el => el.id_teacher == id_teacher )



	    	// Un fin de semana y entre semana medio turno
	    	if( ( existeSabado || existeDomingo ) && ( existeLunes && existeMartes && existeMiercoles && existeJueves && existeViernes )){

	    		// Significa que no debería estar rolada en nigun fin de semana
	    		let existeEntreSemana = gruposActivos.filter( el => { return ( el.lunes == 1 || el.martes == 1 || el.miercoles == 1 || el.jueves == 1 || el.viernes == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

	    		let medioTurno = 0
	    		let medioTurno2 = 0

	    		for( const i in existeEntreSemana ){

	    			const { hora_inicio } = existeEntreSemana[i]

	    			if( hora_inicio.substr(0,5) <= "16:00" ){ medioTurno = 1 }
	    			if( hora_inicio.substr(0,5) >= "16:00" ){ medioTurno2 = 1 }

	    		}

	    		let grupos = existeEntreSemana.map((registro) => { return registro.grupo })

	    		// FULL
	    		if( medioTurno && medioTurno2 ){
	    			this.teachersBonos[i]['categoria'] = 1
	    			this.teachersBonos[i]['bono']      = 1000
	    		}else{ // 1/2 turno + fin de semana
	    			this.teachersBonos[i]['categoria'] = 2
	    			this.teachersBonos[i]['bono']      = 500
	    		}
	    		
	    	}


	    	// Un fin de semana y entre semana medio turno
	    	if( ( existeSabado || existeDomingo ) && ( existeLunes || existeMartes || existeMiercoles || existeJueves || existeViernes )){

	    		// Significa que no debería estar rolada en nigun fin de semana
	    		let existeEntreSemana = gruposActivos.filter( el => { return ( el.lunes == 1 || el.martes == 1 || el.miercoles == 1 || el.jueves == 1 || el.viernes == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

	    		let medioTurno = 0
	    		let medioTurno2 = 0

	    		for( const i in existeEntreSemana ){

	    			const { hora_inicio } = existeEntreSemana[i]

	    			if( hora_inicio.substr(0,5) <= "16:00" ){ medioTurno = 1 }
	    			if( hora_inicio.substr(0,5) >= "16:00" ){ medioTurno2 = 1 }

	    		}

	    		let grupos = existeEntreSemana.map((registro) => { return registro.grupo })

	    		// FULL
	    		if( medioTurno && medioTurno2 ){
	    			this.teachersBonos[i]['categoria'] = 1
	    			this.teachersBonos[i]['bono_l']    = 1000
	    		}else{ // 1/2 turno + fin de semana
	    			this.teachersBonos[i]['categoria'] = 2
	    			this.teachersBonos[i]['bono_l']    = 500
	    		}
	    		
	    	}


	    	// SOLO FIN DE SEMANA
	    	if( ( existeSabado || existeDomingo ) && ( !existeLunes && !existeMartes && !existeMiercoles && !existeJueves && !existeViernes )){

	    		// Significa que no debería estar rolada en nigun fin de semana
	    		let existeEntreSemana = gruposActivos.filter( el => { return ( el.sabado == 0 && el.domingo == 0 ) && ( el[`lu${1}`] == id_teacher && el[`ma${1}`] == id_teacher && el[`mi${1}`] == id_teacher && el[`ju${1}`] == id_teacher && el[`vi${1}`] == id_teacher ) })

	    		if( !existeEntreSemana.length ){
	    			this.teachersBonos[i]['categoria'] = 3
	    			this.teachersBonos[i]['bono']      = 350
	    			this.teachersBonos[i]['bono_l']    = 350
	    		}else{
	    			this.teachersBonos[i]['eliminable'] = 1
	    			this.teachersBonos[i]['bono']       = 0
	    			this.teachersBonos[i]['bono_l']     = 0
	    		}
	    		

	    	}

	    	// SOLO ENTRE SEMANA
	    	if( ( !existeSabado && !existeDomingo ) && ( existeLunes && existeMartes && existeMiercoles && existeJueves && existeViernes )){

	    		// Significa que no debería estar rolada en nigun fin de semana
	    		let existeFinSemana = gruposActivos.filter( el => { return ( el.sabado == 1 || el.domingo == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

	    		if( !existeFinSemana.length ){
	    			this.teachersBonos[i]['categoria'] = 4
            this.teachersBonos[i]['bono']      = 500
	    			this.teachersBonos[i]['bono_l']    = 500
	    		}else{
	    			this.teachersBonos[i]['eliminable'] = 1
	    			this.teachersBonos[i]['bono']       = 0
	    			this.teachersBonos[i]['bono_l']     = 0
	    		}
	    		

	    	}

        // SOLO ENTRE SEMANA
        if( ( !existeSabado && !existeDomingo ) && ( existeLunes || existeMartes || existeMiercoles || existeJueves || existeViernes )){

          // Significa que no debería estar rolada en nigun fin de semana
          let existeFinSemana = gruposActivos.filter( el => { return ( el.sabado == 1 || el.domingo == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

          if( !existeFinSemana.length ){
            this.teachersBonos[i]['categoria'] = 4
            this.teachersBonos[i]['bono_l']    = 500
          }else{
            this.teachersBonos[i]['eliminable'] = 1
            this.teachersBonos[i]['bono_l']     = 0
          }
          

        }
	    }


	    this.teachersBonos.push({
	    	id_teacher:  100,
				teacher:     'TOTAL',
				categoria:   0,
				bono:        this.teachersBonos.map(item => item.bono).reduce((prev, curr) => prev + curr, 0) ,
				bono_l:      this.teachersBonos.map(item => item.bono_l).reduce((prev, curr) => prev + curr, 0) ,
				eliminable:  0,
	    })


    	// // Vamos a sacar los teachers de cada bloque
      // let tlu1 = gruposActivos.filter( el => { return ( el.lunes || el.sabado || el.domingo ) && el[`lu${1}_n`] })
    	// 						.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
			// let tma1 = gruposActivos.filter( el => { return ( el.martes || el.sabado || el.domingo ) && el[`ma${1}_n`] })
    	// 						.map((registro) => { return { id_teacher: registro[`ma${1}`], teacher: registro[`ma${1}_n`]  }})
			// let tmi1 = gruposActivos.filter( el => { return ( el.miercoles || el.sabado || el.domingo ) && el[`mi${1}_n`] })
    	// 						.map((registro) => { return { id_teacher: registro[`mi${1}`], teacher: registro[`mi${1}_n`]  }})
			// let tju1 = gruposActivos.filter( el => { return ( el.jueves || el.sabado || el.domingo ) && el[`ju${1}_n`] })
    	// 						.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
			// let tvi1 = gruposActivos.filter( el => { return ( el.viernes || el.sabado || el.domingo ) && el[`vi${1}_n`] })
    	// 						.map((registro) => { return { id_teacher: registro[`vi${1}`], teacher: registro[`vi${1}_n`]  }})


    	// // Concatenamos a los maestros
      // let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 )

      // // Quitamos los duplicados de los maestros
      // let arregloSinDuplicados = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);

      // // Sacamos los id de los teacher
      // const ids = arregloSinDuplicados.map((registro) => { return registro.id_teacher })

      // this.teachersBonos = this.teachers.filter( el => { return ids.includes( el.id_usuario )  })

      // for( const i in this.teachersBonos ){

      // 	const { id_usuario, nombre_completo } = this.teachersBonos[i]

      // 	if( id_usuario == 1157  ){
	    //   	// Sacar los grupos por TEACHER, que sean fijo
	    //   	const gruposTeacherFijo = gruposActivos.filter( el => el.id_teacher == id_usuario || el.id_teacher_2 == id_usuario )

	    //   	console.log( id_usuario, nombre_completo )
	    //   	let grupoNombres = gruposTeacherFijo.map((registro) => { return registro.grupo }) 

	    //   	for( const i in grupoNombres ){
	    //   		console.log( grupoNombres[i] )
	    //   	}
      // 	}


      // }
    	
    },

    computed:{

    },

    methods:{
      cerrar(){
      	this.dialogBonos.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      },


      validarHoraAsistencia(horaInicio, horaAsistencia) {
		    // Convertir las horas a minutos
		    var inicioMinutos = this.obtenerMinutos(horaInicio);
    		var asistenciaMinutos = this.obtenerMinutos(horaAsistencia);
		    
		    // Verificar si la hora de asistencia es al menos 15 minutos antes de la hora de inicio
		    if (inicioMinutos - asistenciaMinutos >= 15) {
		        return true;
		    } else {
		        return false;
		    }
			},

			obtenerMinutos(hora) {
		    var partesHora = hora.split(":");
		    var horas = parseInt(partesHora[0]);
		    var minutos = parseInt(partesHora[1]);
		    return horas * 60 + minutos;
			},


			compararHorasInicio(eventoA, eventoB) {
		    // Convertir las horas de inicio a minutos
		    var minutosA = this.obtenerMinutos(eventoA.hora_inicio);
		    var minutosB = this.obtenerMinutos(eventoB.hora_inicio);
		    
		    // Comparar los minutos
		    if (minutosA < minutosB) {
		        return -1;
		    } else if (minutosA > minutosB) {
		        return 1;
		    } else {
		        return 0;
		    }
			}

    }
	}
</script>
