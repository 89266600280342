<template>
	<v-dialog
    v-model="dialogNoRolados.estatus"
    hide-overlay
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title primary-title class="text-subtitle-1">
        Teacher No rolados
      </v-card-title>
      <v-card-text >

      	<v-row>
	      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
	          <v-autocomplete
				    	filled
				      :items="[{id: 1, texto: 'Lunes a Miércoles'},{id: 2, texto: 'Jueves y Viernes'},{id: 3, texto: 'Sábado'}, {id: 4, texto: 'Domingo'}]"
				      v-model="curso"
				      item-text="texto"
				      item-value="id"
				      label="Frecuencia"
				      clearable
				      dense
	            single-line
				    ></v-autocomplete>
	        </v-col>

	      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
	          <v-text-field
	            v-model="search"
	            dense
	            filled
	            single-line
	            label="Buscar"
	            append-icon="mdi-magnify"
	          ></v-text-field>  
	        </v-col>
      	</v-row>

        <v-data-table
          :headers="headersCerrados"
          :items="filterGrupos"
          class="elevation-0"
          dense
          :search="search"
        >
        	<!-- Valores que ocupan botons, chips, iconos, etc -->
          <template v-slot:item.disponibilidad="{ item }">
            <span v-if="curso == 1 ">{{ item.lunes_miercoles }}</span>
            <span v-if="curso == 2 ">{{ item.jueves_viernes }}</span>
            <span v-if="curso == 3 ">{{ item.sabado }}</span>
            <span v-if="curso == 4 ">{{ item.domingo }}</span>
          </template>
            
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
        	color="black"
        	dark 
        	tile
        	@click="dialogNoRolados.estatus = false"
        >Cancelar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogNoRolados',
			'prospectosCerrados',
			'gruposRoles',
			'teachers'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      headersCerrados: [
        { text: 'Folio',          value: 'id_usuario'      },
        { text: 'Nombre',         value: 'nombre_completo' },  
        { text: 'Teléfono',       value: 'telefono1'       },  
        { text: 'Sucursal',       value: 'plantel'         },  
        { text: 'Motivo',         value: 'MOTIVO'          },  
        { text: 'Disponibilidad', value: 'disponibilidad'  },  
      ],
      search:'',
      curso: null,

    }),

    created(){
    	this.teacherActivos = this.teachers.filter( el =>{ return el.activo == 1 })

    	let gruposActivos = this.gruposRoles.filter( el => { return el.deleted == 0 })

    	// PONER LOS MOTIVOS
	    const gruposEntreSemana   = gruposActivos.filter( el => { return el.sabado == 0 && el.domingo == 0 })

	    const teacher1EntreSemana = gruposEntreSemana.filter( el => { return el.id_teacher }).map((registro) => { return registro.id_teacher })
	    const teacher2EntreSemana = gruposEntreSemana.filter( el => { return el.id_teacher_2 }).map((registro) => { return registro.id_teacher_2 })

	    const idTeachersEntreSemana = teacher1EntreSemana.concat( teacher2EntreSemana )

	    // Sacando a los teacher que solo tienen sabatino o dominical y que no tienen en ningun otro grupo
	    const gruposSabaDomingos = gruposActivos.filter( el => { return el.sabado == 1 || el.domingo == 1 })

	    // Sacar los teacher que están en esos grupos
	    const teacher1SabaDom = gruposSabaDomingos.filter( el => { return !idTeachersEntreSemana.includes( el.id_teacher ) }).map((registro) => { return registro.id_teacher })
	    const teacher2SabaDom = gruposSabaDomingos.filter( el => { return !idTeachersEntreSemana.includes( el.id_teacher_2 ) }).map((registro) => { return registro.id_teacher_2 })

	    const idTeachersSabaDom = teacher1SabaDom.concat( teacher2SabaDom )

	    // Grupos solo entr semana
	    for( const i in this.teacherActivos ){
	    	console.log(1)

	      const { id_usuario } = this.teacherActivos[i]

	      if( idTeachersSabaDom.includes( id_usuario ) ){
	        this.teacherActivos[i]['MOTIVO'] = "SOLO SABADO Y/O DOMINGO"
	      }else if ( idTeachersEntreSemana.includes( id_usuario ) ) {
	        this.teacherActivos[i]['MOTIVO'] = "ROLADO ENTRE SEMANA"
	      }else{
	        this.teacherActivos[i]['MOTIVO'] = "NO ROLADO"
	      }
	    }

	    // this.teacherActivos = this.teacherActivos.concat( teacherSinRol )

    	console.log( this.teacherActivos )
    },

    computed:{
  		filterGrupos(){

  			let grupos = this.gruposRoles.filter( el => el.deleted == 0 ) 

  			if( this.curso == 1 ){
  				// Vamos a sacar los teachers de cada bloque
	        let tlu1 = grupos.filter( el => { return ( el.lunes ) && el[`lu${1}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
	        let tlu2 = grupos.filter( el => { return ( el.lunes ) && el[`lu${2}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
	        let tlu3 = grupos.filter( el => { return ( el.lunes ) && el[`lu${3}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
	        let tlu4 = grupos.filter( el => { return ( el.lunes ) && el[`lu${4}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})


	        let tma1 = grupos.filter( el => { return ( el.martes ) && el[`ma${1}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ma${1}`], teacher: registro[`ma${1}_n`]  }})
	        let tma2 = grupos.filter( el => { return ( el.martes ) && el[`ma${2}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ma${2}`], teacher: registro[`ma${2}_n`]  }})
	        let tma3 = grupos.filter( el => { return ( el.martes ) && el[`ma${3}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ma${3}`], teacher: registro[`ma${3}_n`]  }})
	        let tma4 = grupos.filter( el => { return ( el.martes ) && el[`ma${4}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ma${4}`], teacher: registro[`ma${4}_n`]  }})

	        let tmi1 = grupos.filter( el => { return ( el.miercoles ) && el[`mi${1}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`mi${1}`], teacher: registro[`mi${1}_n`]  }})
	        let tmi2 = grupos.filter( el => { return ( el.miercoles ) && el[`mi${2}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`mi${2}`], teacher: registro[`mi${2}_n`]  }})
	        let tmi3 = grupos.filter( el => { return ( el.miercoles ) && el[`mi${3}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`mi${3}`], teacher: registro[`mi${3}_n`]  }})
	        let tmi4 = grupos.filter( el => { return ( el.miercoles ) && el[`mi${4}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`mi${4}`], teacher: registro[`mi${4}_n`]  }})

	        // Concatenamos a los maestros
	        let arregloTeachers = tlu1.concat( tlu2 ).concat( tlu3 ).concat( tlu4 ).concat( tma1 ).concat( tma2 ).concat( tma3 ).concat( tma4 ).concat( tmi1 ).concat( tmi2 ).concat( tmi3 ).concat( tmi4 )

	        // Quitamos los duplicados de los maestros
	        let arregloSinDuplicados = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);

	        // Sacamos los id de los teacher
	        const ids = arregloSinDuplicados.map((registro) => { return registro.id_teacher })

	        return this.teacherActivos.filter( el => { return !ids.includes( el.id_usuario )  })

  			}

  			if( this.curso == 2 ){
  				// Vamos a sacar los teachers de cada bloque
	        let tju1 = grupos.filter( el => { return ( el.jueves ) && el[`ju${1}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
	        let tju2 = grupos.filter( el => { return ( el.jueves ) && el[`ju${2}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
	        let tju3 = grupos.filter( el => { return ( el.jueves ) && el[`ju${3}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
	        let tju4 = grupos.filter( el => { return ( el.jueves ) && el[`ju${4}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})


	        let tvi1 = grupos.filter( el => { return ( el.viernes ) && el[`vi${1}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`vi${1}`], teacher: registro[`vi${1}_n`]  }})
	        let tvi2 = grupos.filter( el => { return ( el.viernes ) && el[`vi${2}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`vi${2}`], teacher: registro[`vi${2}_n`]  }})
	        let tvi3 = grupos.filter( el => { return ( el.viernes ) && el[`vi${3}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`vi${3}`], teacher: registro[`vi${3}_n`]  }})
	        let tvi4 = grupos.filter( el => { return ( el.viernes ) && el[`vi${4}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`vi${4}`], teacher: registro[`vi${4}_n`]  }})

	        // Concatenamos a los maestros
	        let arregloTeachers = tju1.concat( tju2 ).concat( tju3 ).concat( tju4 ).concat( tvi1 ).concat( tvi2 ).concat( tvi3 ).concat( tvi4 )

	        // Quitamos los duplicados de los maestros
	        let arregloSinDuplicados = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);

	        // Sacamos los id de los teacher
	        const ids = arregloSinDuplicados.map((registro) => { return registro.id_teacher })

	        return this.teacherActivos.filter( el => { return !ids.includes( el.id_usuario )  })
  				
  			}

  			if( this.curso == 3 ){
  				// Vamos a sacar los teachers de cada bloque
	        let tsa1 = grupos.filter( el => { return ( el.sabado ) && el[`lu${1}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
	        let tsa2 = grupos.filter( el => { return ( el.sabado ) && el[`lu${2}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
	        let tsa3 = grupos.filter( el => { return ( el.sabado ) && el[`lu${3}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
	        let tsa4 = grupos.filter( el => { return ( el.sabado ) && el[`lu${4}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})
	        // Vamos a sacar los teachers de cada bloque
	        let tsa5 = grupos.filter( el => { return ( el.sabado ) && el[`ju${1}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
	        let tsa6 = grupos.filter( el => { return ( el.sabado ) && el[`ju${2}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
	        let tsa7 = grupos.filter( el => { return ( el.sabado ) && el[`ju${3}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
	        let tsa8 = grupos.filter( el => { return ( el.sabado ) && el[`ju${4}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})

	        // Concatenamos a los maestros
	        let arregloTeachers = tsa1.concat( tsa2 ).concat( tsa3 ).concat( tsa4 ).concat( tsa5 ).concat( tsa6 ).concat( tsa7 ).concat( tsa8 )

	        // Quitamos los duplicados de los maestros
	        let arregloSinDuplicados = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);

	        // Sacamos los id de los teacher
	        const ids = arregloSinDuplicados.map((registro) => { return registro.id_teacher })

	        return this.teacherActivos.filter( el => { return !ids.includes( el.id_usuario )  })
  			}

  			if( this.curso == 4 ){
  				// Vamos a sacar los teachers de cada bloque
	        let tdo1 = grupos.filter( el => { return ( el.domingo ) && el[`lu${1}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
	        let tdo2 = grupos.filter( el => { return ( el.domingo ) && el[`lu${2}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
	        let tdo3 = grupos.filter( el => { return ( el.domingo ) && el[`lu${3}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
	        let tdo4 = grupos.filter( el => { return ( el.domingo ) && el[`lu${4}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})
	        // Vamos a sacar los teachers de cada bloque
	        let tdo5 = grupos.filter( el => { return ( el.domingo ) && el[`ju${1}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
	        let tdo6 = grupos.filter( el => { return ( el.domingo ) && el[`ju${2}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
	        let tdo7 = grupos.filter( el => { return ( el.domingo ) && el[`ju${3}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
	        let tdo8 = grupos.filter( el => { return ( el.domingo ) && el[`ju${4}_n`] })
	        							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})

	        // Concatenamos a los maestros
	        let arregloTeachers = tdo1.concat( tdo2 ).concat( tdo3 ).concat( tdo4 ).concat( tdo5 ).concat( tdo6 ).concat( tdo7 ).concat( tdo8 )

	        // Quitamos los duplicados de los maestros
	        let arregloSinDuplicados = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);

	        // Sacamos los id de los teacher
	        const ids = arregloSinDuplicados.map((registro) => { return registro.id_teacher })

	        return this.teacherActivos.filter( el => { return !ids.includes( el.id_usuario )  })
  			}

  			return []
  		}
    },

    methods:{
      cerrar(){
      	this.dialogNoRolados.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      }
    }
	}
</script>
