<template>
	<v-dialog
    v-model="dialogComparativa.estatus"
    hide-overlay
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title primary-title class="text-subtitle-1">
        Comparativa de clases
      </v-card-title>
      <v-card-text >

      	<div class="black--text">
      		Total de horas Sabaitnas:   {{ totalHorasSabatinas }}<br/>
      		Total de horas Dominicales: {{ totalHorasDominicales }}<br/>
      		Total de horas Lunes a Vi:  {{ totalHorasLV }}<br/>
      		Total de horas:             {{ totalHoras }}
      	</div>
      	<br/>
      	<v-row>

      		<br/>
	      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
	          <v-text-field
	            v-model="search"
	            dense
	            filled
	            single-line
	            label="Buscar"
	            append-icon="mdi-magnify"
	          ></v-text-field>  
	        </v-col>
      	</v-row>

        <v-data-table
          :headers="headersCerrados"
          :items="teachers"
          class="elevation-0"
          dense
          :search="search"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
        	color="black"
        	dark 
        	tile
        	@click="dialogComparativa.estatus = false"
        >Cancelar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogComparativa',
			'prospectosCerrados',
			'gruposRoles',
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      teachersBonos: [],

      headersCerrados: [
        { text: 'id',       value: 'id_teacher'  },
        { text: 'Teacher',  value: 'teacher'     },  
        { text: 'L-V1'   ,  value: 'lv1'         },  
        { text: 'S1'     ,  value: 's1'          },  
        { text: 'L-V2'   ,  value: 'lv2'         },  
        { text: 'S2'     ,  value: 's2'          },  
        { text: 'Total'  ,  value: 'total'       },  
      ],
      search:'',
      gruposActivos:[],


    }),

    created(){
    	this.gruposActivos = this.gruposRoles.filter( el => { return el.deleted == 0 })
    	console.log( this.gruposActivos.length )

    	this.limpiarGrupos()

    	this.sacarTeachers( )

    	this.sacarHorasSabado1( )
    	
    	this.sacarHorasSabado2( )

    	this.sacarHorasLV1( )

    	this.sacarHorasLV2( )

    	this.sacarTotalesPorTeacher( )

    	this.sacarTotales( )

    },

    computed:{

    	totalHoras(){
    		return this.totalHorasSabatinas + this.totalHorasDominicales + this.totalHorasLV
    	},

    	totalHorasSabatinas(){
    		return this.gruposActivos.filter( el => { return el.deleted == 0 && el.sabado }).map(item => item.horas_semana ).reduce((prev, curr) => prev + curr, 0)
    	},

    	totalHorasDominicales(){
    		return this.gruposActivos.filter( el => { return el.deleted == 0 && el.domingo }).map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0)
    	},

    	totalHorasLV(){
    		return this.gruposActivos.filter( el => { return el.deleted == 0 && ( !el.sabado && !el.domingo ) }).map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0)
    	},

    },

    methods:{
      cerrar(){
      	this.dialogComparativa.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      },

      limpiarGrupos ( ) {
      	for( const i in this.gruposActivos ){
	    		const { grupo } = this.gruposActivos[i]
	      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
	    			this.gruposActivos[i].ju1_n = null
	    			this.gruposActivos[i].ju1   = null
	    			this.gruposActivos[i].vi1_n = null
	    			this.gruposActivos[i].vi1   = null
	    			this.gruposActivos[i].ju2_n = null
	    			this.gruposActivos[i].ju2   = null
	    			this.gruposActivos[i].vi2_n = null
	    			this.gruposActivos[i].vi2   = null
	    			this.gruposActivos[i].ju3_n = null
	    			this.gruposActivos[i].ju3   = null
	    			this.gruposActivos[i].vi3_n = null
	    			this.gruposActivos[i].vi3   = null
	    			this.gruposActivos[i].ju4_n = null
	    			this.gruposActivos[i].ju4   = null
	    			this.gruposActivos[i].vi4_n = null
	    			this.gruposActivos[i].vi4   = null
	    		}      		
	    	}
      },

      sacarTeachers ( ) {
      	// Sacar a los teachers que dan clase
	    	// Vamos a sacar los teachers de cada bloque
	      let tlu1 = this.gruposActivos.filter( el => { return el[`lu1_n`] }).map((registro) => { return { id_teacher: registro[`lu1`], teacher: registro[`lu1_n`]}})
	      let tma1 = this.gruposActivos.filter( el => { return el[`ma1_n`] }).map((registro) => { return { id_teacher: registro[`ma1`], teacher: registro[`ma1_n`]}})
	      let tmi1 = this.gruposActivos.filter( el => { return el[`mi1_n`] }).map((registro) => { return { id_teacher: registro[`mi1`], teacher: registro[`mi1_n`]}})
	      let tju1 = this.gruposActivos.filter( el => { return el[`ju1_n`] }).map((registro) => { return { id_teacher: registro[`ju1`], teacher: registro[`ju1_n`]}})
	      let tvi1 = this.gruposActivos.filter( el => { return el[`vi1_n`] }).map((registro) => { return { id_teacher: registro[`vi1`], teacher: registro[`vi1_n`]}})

	      let tlu2 = this.gruposActivos.filter( el => { return el[`lu2_n`] }).map((registro) => { return { id_teacher: registro[`lu2`], teacher: registro[`lu2_n`]}})
	      let tma2 = this.gruposActivos.filter( el => { return el[`ma2_n`] }).map((registro) => { return { id_teacher: registro[`ma2`], teacher: registro[`ma2_n`]}})
	      let tmi2 = this.gruposActivos.filter( el => { return el[`mi2_n`] }).map((registro) => { return { id_teacher: registro[`mi2`], teacher: registro[`mi2_n`]}})
	      let tju2 = this.gruposActivos.filter( el => { return el[`ju2_n`] }).map((registro) => { return { id_teacher: registro[`ju2`], teacher: registro[`ju2_n`]}})
	      let tvi2 = this.gruposActivos.filter( el => { return el[`vi2_n`] }).map((registro) => { return { id_teacher: registro[`vi2`], teacher: registro[`vi2_n`]}})

	      let tlu3 = this.gruposActivos.filter( el => { return el[`lu3_n`] }).map((registro) => { return { id_teacher: registro[`lu3`], teacher: registro[`lu3_n`]}})
	      let tma3 = this.gruposActivos.filter( el => { return el[`ma3_n`] }).map((registro) => { return { id_teacher: registro[`ma3`], teacher: registro[`ma3_n`]}})
	      let tmi3 = this.gruposActivos.filter( el => { return el[`mi3_n`] }).map((registro) => { return { id_teacher: registro[`mi3`], teacher: registro[`mi3_n`]}})
	      let tju3 = this.gruposActivos.filter( el => { return el[`ju3_n`] }).map((registro) => { return { id_teacher: registro[`ju3`], teacher: registro[`ju3_n`]}})
	      let tvi3 = this.gruposActivos.filter( el => { return el[`vi3_n`] }).map((registro) => { return { id_teacher: registro[`vi3`], teacher: registro[`vi3_n`]}})

	      let tlu4 = this.gruposActivos.filter( el => { return el[`lu4_n`] }).map((registro) => { return { id_teacher: registro[`lu4`], teacher: registro[`lu4_n`]}})
	      let tma4 = this.gruposActivos.filter( el => { return el[`ma4_n`] }).map((registro) => { return { id_teacher: registro[`ma4`], teacher: registro[`ma4_n`]}})
	      let tmi4 = this.gruposActivos.filter( el => { return el[`mi4_n`] }).map((registro) => { return { id_teacher: registro[`mi4`], teacher: registro[`mi4_n`]}})
	      let tju4 = this.gruposActivos.filter( el => { return el[`ju4_n`] }).map((registro) => { return { id_teacher: registro[`ju4`], teacher: registro[`ju4_n`]}})
	      let tvi4 = this.gruposActivos.filter( el => { return el[`vi4_n`] }).map((registro) => { return { id_teacher: registro[`vi4`], teacher: registro[`vi4_n`]}})

	      // Concatenamos a los maestros
	      let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 ).concat( tlu2 ).concat( tma2 ).concat( tmi2 ).concat( tju2 ).concat( tvi2 ).concat( tlu3 ).concat( tma3 ).concat( tmi3 ).concat( tju3 ).concat( tvi3 ).concat( tlu4 ).concat( tma4 ).concat( tmi4 ).concat( tju4 ).concat( tvi4 )
	        
	      // Quitamos los duplicados de los maestros
	      this.teachers = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);
      },

      sacarHorasSabado1 ( ) {

      	for( const i in this.teachers ){

					// Sacamos el id del teacher
					const { id_teacher } = this.teachers[i]

					// Sacamos las clases donde esta el maestro 
					let clasesSabado = this.gruposActivos.filter( el => el[`lu1`] == id_teacher || el[`ma1`] == id_teacher || el[`mi1`] == id_teacher || el[`ju1`] == id_teacher || el[`vi1`] == id_teacher )

					// Lo separamos por escuela
					const clasesFast      = clasesSabado.filter( el => el.id_unidad_negocio == 2 && el.sabado )
					const clasesInbi      = clasesSabado.filter( el => el.id_unidad_negocio == 1 && el.sabado )
					let   clasesTeens     = clasesInbi.filter( el => el.grupo.match('TEENS') && el.sabado )

					// Contador de horas de fast por teacher
					let contadorHorasFast = 0

					for( const i in clasesFast ){

						contadorHorasFast += clasesFast[i][`lu1`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`ma1`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`mi1`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`ju1`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`vi1`] == id_teacher ? 1 : 0

					}

					// Contador de horas de Inbi por teacher
					let contadorHorasInbi = 0

					for( const i in clasesInbi ){

						const { grupo } = clasesInbi[i]

						if( grupo.match('TEENS') ){

						}else{
							contadorHorasInbi += clasesInbi[i][`lu1`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`ma1`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`mi1`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`ju1`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`vi1`] == id_teacher ? 1 : 0

						}
					}

					// CLASES TEENS
					let contadorHorasTeens = 0

					for( const i in clasesTeens ){
						const { grupo } = clasesTeens[i]
						contadorHorasTeens += 3
					}

					this.teachers[i]['s1'] = contadorHorasFast + contadorHorasInbi + contadorHorasTeens

				}
      },

      sacarHorasSabado2 ( ) {

      	for( const i in this.teachers ){

					// Sacamos el id del teacher
					const { id_teacher } = this.teachers[i]

					// Sacamos las clases donde esta el maestro 
					let clasesSabado = this.gruposActivos.filter( el => el[`lu2`] == id_teacher || el[`ma2`] == id_teacher || el[`mi2`] == id_teacher || el[`ju2`] == id_teacher || el[`vi2`] == id_teacher )

					// Lo separamos por escuela
					const clasesFast      = clasesSabado.filter( el => el.id_unidad_negocio == 2 && el.sabado )
					const clasesInbi      = clasesSabado.filter( el => el.id_unidad_negocio == 1 && el.sabado )
					let   clasesTeens     = clasesInbi.filter( el => el.grupo.match('TEENS') && el.sabado )

					// Contador de horas de fast por teacher
					let contadorHorasFast = 0

					for( const i in clasesFast ){

						contadorHorasFast += clasesFast[i][`lu2`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`ma2`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`mi2`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`ju2`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`vi2`] == id_teacher ? 1 : 0

					}

					// Contador de horas de Inbi por teacher
					let contadorHorasInbi = 0

					for( const i in clasesInbi ){

						const { grupo } = clasesInbi[i]

						if( grupo.match('TEENS') ){

						}else{
							contadorHorasInbi += clasesInbi[i][`lu2`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`ma2`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`mi2`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`ju2`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`vi2`] == id_teacher ? 1 : 0

						}
					}

					// CLASES TEENS
					let contadorHorasTeens = 0

					for( const i in clasesTeens ){
						const { grupo } = clasesTeens[i]
						contadorHorasTeens += 3
					}

					this.teachers[i]['s2'] = contadorHorasFast + contadorHorasInbi + contadorHorasTeens

				}
      },

      sacarHorasLV1 ( ) {

      	for( const i in this.teachers ){

					// Sacamos el id del teacher
					const { id_teacher } = this.teachers[i]

					// Sacamos las clases donde esta el maestro 
					let clasesSabado = this.gruposActivos.filter( el => el[`lu2`] == id_teacher || el[`ma2`] == id_teacher || el[`mi2`] == id_teacher || el[`ju2`] == id_teacher || el[`vi2`] == id_teacher )

					// Lo separamos por escuela
					const clasesFast      = clasesSabado.filter( el => el.id_unidad_negocio == 2 && el.sabado )
					const clasesInbi      = clasesSabado.filter( el => el.id_unidad_negocio == 1 && el.sabado )
					let   clasesTeens     = clasesInbi.filter( el => el.grupo.match('TEENS') && el.sabado )

					// Contador de horas de fast por teacher
					let contadorHorasFast = 0

					for( const i in clasesFast ){

						contadorHorasFast += clasesFast[i][`lu2`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`ma2`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`mi2`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`ju2`] == id_teacher ? 1 : 0
						contadorHorasFast += clasesFast[i][`vi2`] == id_teacher ? 1 : 0

					}

					// Contador de horas de Inbi por teacher
					let contadorHorasInbi = 0

					for( const i in clasesInbi ){

						const { grupo } = clasesInbi[i]

						if( grupo.match('TEENS') ){

						}else{
							contadorHorasInbi += clasesInbi[i][`lu2`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`ma2`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`mi2`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`ju2`] == id_teacher ? 1 : 0
							contadorHorasInbi += clasesInbi[i][`vi2`] == id_teacher ? 1 : 0

						}
					}

					// CLASES TEENS
					let contadorHorasTeens = 0

					for( const i in clasesTeens ){
						const { grupo } = clasesTeens[i]
						contadorHorasTeens += 3
					}

					this.teachers[i]['s2'] = contadorHorasFast + contadorHorasInbi + contadorHorasTeens

				}
      },

      sacarHorasLV1( ){

				let bloqueInicial = [1,2].includes( 2 ) ? 1 : 3
    		let bloqueFinal   = [1,2].includes( 2 ) ? 2 : 4

        // Recorremos los maestros para ir agregando las cosas
				for( const i in this.teachers ){

					// Sacamos el id del teacher
					const { id_teacher, teacher } = this.teachers[i]

					// Sacamos las clases que le pertenecen al teacher 
					const clasesTeacher = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																		.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher) || ['lu', 'ma', 'mi', 'ju', 'vi']
																		.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher)) && ( !el.domingo && !el.sabado && el.horas_dia != 1.5 ));

					// SACAR LAS CLASES DOMINICALES
					const clasesTeacherDomingo = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.domingo );

					// SACAR LAS CLASES SABATINO
					const clasesTeacherSabado = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.sabado );

					// CLASES DE 1.5 HORAS
					const clasesLMV = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.horas_dia == 1.5 );

					// Lo separamos por escuela
					const clasesFast = clasesTeacher.filter( el => { return el.id_unidad_negocio == 2 && !el.sabado})
					const clasesInbi = clasesTeacher.filter( el => { return el.id_unidad_negocio == 1 && !el.sabado})

					// CLASES FAST DOMINGO
					const clasesFastDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 2 && !el.sabado})
					const clasesInbiDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 1 && !el.sabado})

					// CLASES LMV
					const clasesFastLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 2 && !el.sabado})
					const clasesInbiLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 1 && !el.sabado})

					let clasesFastLV = 0
					let clasesFastLM = 0
					let clasesFastDom = 0
					let clasesInbiLV = 0
					let clasesInbiLM = 0
					let clasesInbiDom = 0


					// CONTADOR ENTRE SEMANA
					let contadorBloquesFast = 0
					for( const i in clasesFast ){

						// ENTRE SEMANA
						contadorBloquesFast += clasesFast[i][`lu${bloqueInicial}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ma${bloqueInicial}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`mi${bloqueInicial}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ju${bloqueInicial}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`vi${bloqueInicial}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0

						// ENTRE SEMANA
						contadorBloquesFast += clasesFast[i][`lu${bloqueFinal}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ma${bloqueFinal}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`mi${bloqueFinal}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ju${bloqueFinal}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`vi${bloqueFinal}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0
					}

					clasesFastLV += contadorBloquesFast

					// CONTADOR FAST DOMINGOS
					let contadorBloquesFastDomingo = 0
					for( const i in clasesFastDomingo ){

						// DOMINICALES
						contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0

						// DOMINICALES
						clasesFastDom += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						clasesFastDom += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0
					}

					// CONTADOR CLASES DE 1.5 HORAS
					let contadorBloquesFastLMV = 0
					for( const i in clasesFastLMV ){

						// ENTRE SEMANA
						contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

						contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

						// CLASES// ENTRE SEMANA
						clasesFastLM += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? 1 : 0

						clasesFastLM += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? 1 : 0
					}

					// INBIIIIIIIIIII CLASES ENTRE SEMANA
					// CONTADOR ENTRE SEMANA
					let contadorBloquesINBI = 0
					for( const i in clasesInbi ){

						// ENTRE SEMANA BOQUE 1 o 3
						contadorBloquesINBI += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0
						// ENTRE SEMANA BOQUE 2 o 4
						contadorBloquesINBI += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0

						// ENTRE SEMANA BOQUE 1 o 3
						clasesInbiLV += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0
						// ENTRE SEMANA BOQUE 2 o 4
						clasesInbiLV += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0

					}

					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiDomingo = 0
					for( const i in clasesInbiDomingo ){

						// DOMINICALES
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

						clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

						clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

					}

					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiLMV = 0
					for( const i in clasesInbiLMV ){

						// ENTRE SEMANA
						contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0

						contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0


						clasesInbiLM += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0

						clasesInbiLM += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0
					}


					this.teachers[i]['lv1'] = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV 
			
				}
      },

      sacarHorasLV2( ){

				let bloqueInicial = [1,2].includes( 4 ) ? 1 : 3
    		let bloqueFinal   = [1,2].includes( 4 ) ? 2 : 4

        // Recorremos los maestros para ir agregando las cosas
				for( const i in this.teachers ){

					// Sacamos el id del teacher
					const { id_teacher, teacher } = this.teachers[i]

					// Sacamos las clases que le pertenecen al teacher 
					const clasesTeacher = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																		.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher) || ['lu', 'ma', 'mi', 'ju', 'vi']
																		.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher)) && ( !el.domingo && !el.sabado && el.horas_dia != 1.5 ));

					// SACAR LAS CLASES DOMINICALES
					const clasesTeacherDomingo = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.domingo );

					// SACAR LAS CLASES SABATINO
					const clasesTeacherSabado = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.sabado );

					// CLASES DE 1.5 HORAS
					const clasesLMV = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.horas_dia == 1.5 );

					// Lo separamos por escuela
					const clasesFast = clasesTeacher.filter( el => { return el.id_unidad_negocio == 2 && !el.sabado})
					const clasesInbi = clasesTeacher.filter( el => { return el.id_unidad_negocio == 1 && !el.sabado})

					// CLASES FAST DOMINGO
					const clasesFastDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 2 && !el.sabado})
					const clasesInbiDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 1 && !el.sabado})

					// CLASES LMV
					const clasesFastLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 2 && !el.sabado})
					const clasesInbiLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 1 && !el.sabado})

					let clasesFastLV = 0
					let clasesFastLM = 0
					let clasesFastDom = 0
					let clasesInbiLV = 0
					let clasesInbiLM = 0
					let clasesInbiDom = 0


					// CONTADOR ENTRE SEMANA
					let contadorBloquesFast = 0
					for( const i in clasesFast ){

						// ENTRE SEMANA
						contadorBloquesFast += clasesFast[i][`lu${bloqueInicial}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ma${bloqueInicial}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`mi${bloqueInicial}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ju${bloqueInicial}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`vi${bloqueInicial}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0

						// ENTRE SEMANA
						contadorBloquesFast += clasesFast[i][`lu${bloqueFinal}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ma${bloqueFinal}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`mi${bloqueFinal}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ju${bloqueFinal}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`vi${bloqueFinal}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0
					}

					clasesFastLV += contadorBloquesFast

					// CONTADOR FAST DOMINGOS
					let contadorBloquesFastDomingo = 0
					for( const i in clasesFastDomingo ){

						// DOMINICALES
						contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0

						// DOMINICALES
						clasesFastDom += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						clasesFastDom += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0
					}

					// CONTADOR CLASES DE 1.5 HORAS
					let contadorBloquesFastLMV = 0
					for( const i in clasesFastLMV ){

						// ENTRE SEMANA
						contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

						contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

						// CLASES// ENTRE SEMANA
						clasesFastLM += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? 1 : 0

						clasesFastLM += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? 1 : 0
					}

					// INBIIIIIIIIIII CLASES ENTRE SEMANA
					// CONTADOR ENTRE SEMANA
					let contadorBloquesINBI = 0
					for( const i in clasesInbi ){

						// ENTRE SEMANA BOQUE 1 o 3
						contadorBloquesINBI += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0
						// ENTRE SEMANA BOQUE 2 o 4
						contadorBloquesINBI += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0

						// ENTRE SEMANA BOQUE 1 o 3
						clasesInbiLV += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0
						// ENTRE SEMANA BOQUE 2 o 4
						clasesInbiLV += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0

					}

					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiDomingo = 0
					for( const i in clasesInbiDomingo ){

						// DOMINICALES
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

						clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

						clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

					}

					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiLMV = 0
					for( const i in clasesInbiLMV ){

						// ENTRE SEMANA
						contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0

						contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0


						clasesInbiLM += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0

						clasesInbiLM += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0
					}


					this.teachers[i]['lv2'] = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV 
			
				}
      },

      sacarTotales ( ){

      	this.teachers.push({
      		id_teacher: 100000,
	        teacher   : 'TOTAL',
	        s1        : this.teachers.map(item => item.s1).reduce((prev, curr) => prev + curr, 0),
	        s2        : this.teachers.map(item => item.s2).reduce((prev, curr) => prev + curr, 0),
	        lv1       : this.teachers.map(item => item.lv1).reduce((prev, curr) => prev + curr, 0),
	        lv2       : this.teachers.map(item => item.lv2).reduce((prev, curr) => prev + curr, 0),
	        total     : this.teachers.map(item => item.total).reduce((prev, curr) => prev + curr, 0),
      	})
      },

      sacarTotalesPorTeacher ( ) {

      	for( const i in this.teachers ){

      		const { s1, s2, lv1, lv2 } = this.teachers[i]

      		this.teachers[i]['total'] = s1 + s2 + lv1 + lv2

      	}

      }

    }
	}
</script>
