<template>
	<v-dialog
    v-model="dialogConfirmados.estatus"
    hide-overlay
    persistent
    max-width="1000"
  >
    <v-card>
      <v-card-title primary-title class="text-subtitle-1">
        Confirmación de roles
      </v-card-title>
      <v-card-text >

      	<v-row justify="end">
	      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
	          <v-text-field
	            v-model="search"
	            dense
	            filled
	            single-line
	            label="Buscar"
	            append-icon="mdi-magnify"
	            hide-details
	          ></v-text-field>  
	        </v-col>
      	</v-row>

      	<v-row justify="end">
      		<v-col cols="12">
      			<v-radio-group
				      v-model="opcion"
				      row
				    >
				      <v-radio
				        label="Todos"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="Confirmados"
				        :value="2"
				      ></v-radio>
				      <v-radio
				        label="Sin confirmar"
				        :value="3"
				      ></v-radio>
				      <v-radio
				        label="Rechazados"
				        :value="4"
				      ></v-radio>
				    </v-radio-group>
      		</v-col>
      	</v-row>

        <v-data-table
          :headers="headersCerrados"
          :items="filterGrupos"
          class="elevation-0"
          dense
          :search="search"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
        	color="black"
        	dark 
        	tile
        	@click="dialogConfirmados.estatus = false"
        >Cancelar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogConfirmados',
			'prospectosCerrados',
			'gruposRoles',
			'teachers'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      headersCerrados: [
        { text: 'Folio',          value: 'id_usuario'      },
        { text: 'Nombre',         value: 'nombre_completo' },  
        { text: 'Teléfono',       value: 'telefono1'       },  
        { text: 'Sucursal',       value: 'plantel'         },  
      ],
      search:'',
      curso: null,
      opcion: 1

    }),

    created(){
    	this.teacherActivos = this.teachers.filter( el =>{ return el.activo == 1 })

    	// Recorremos a los teachers
    	for( const i in this.teacherActivos ){

    		// Desestrucutruamos el id_usuario
    		const { id_usuario } = this.teacherActivos[i]

    		// Sacamos los grupos activos de ese teacher
    		let gruposActivos = this.gruposRoles.filter(el => {
				  return el.deleted === 0 && el.liberar === 1 &&
				    ['lu', 'ma', 'mi', 'ju', 'vi'].some(dia => el[dia + '1'] === id_usuario || el[dia + '2'] === id_usuario || el[dia + '3'] === id_usuario || el[dia + '4'] === id_usuario);
				});

        for( const j in  gruposActivos ){

          if( gruposActivos[j]['lu1_confirmacion'] == 2  && gruposActivos[j]['lu1'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['ma1_confirmacion'] == 2  && gruposActivos[j]['ma1'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['mi1_confirmacion'] == 2  && gruposActivos[j]['mi1'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['ju1_confirmacion'] == 2  && gruposActivos[j]['ju1'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['vi1_confirmacion'] == 2  && gruposActivos[j]['vi1'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }

          // *******************

          if( gruposActivos[j]['lu2_confirmacion'] == 2  && gruposActivos[j]['lu2'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['ma2_confirmacion'] == 2  && gruposActivos[j]['ma2'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['mi2_confirmacion'] == 2  && gruposActivos[j]['mi2'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['ju2_confirmacion'] == 2  && gruposActivos[j]['ju2'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['vi2_confirmacion'] == 2  && gruposActivos[j]['vi2'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }

          // *****************
          if( gruposActivos[j]['lu3_confirmacion'] == 2  && gruposActivos[j]['lu3'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['ma3_confirmacion'] == 2  && gruposActivos[j]['ma3'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['mi3_confirmacion'] == 2  && gruposActivos[j]['mi3'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['ju3_confirmacion'] == 2  && gruposActivos[j]['ju3'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['vi3_confirmacion'] == 2  && gruposActivos[j]['vi3'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }

          // **************
          if( gruposActivos[j]['lu4_confirmacion'] == 2  && gruposActivos[j]['lu4'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['ma4_confirmacion'] == 2  && gruposActivos[j]['ma4'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['mi4_confirmacion'] == 2  && gruposActivos[j]['mi4'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['ju4_confirmacion'] == 2  && gruposActivos[j]['ju4'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }
          if( gruposActivos[j]['vi4_confirmacion'] == 2  && gruposActivos[j]['vi4'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 4 ; break  }


          if( gruposActivos[j]['lu1_confirmacion'] == 0  && gruposActivos[j]['lu1'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['ma1_confirmacion'] == 0  && gruposActivos[j]['ma1'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['mi1_confirmacion'] == 0  && gruposActivos[j]['mi1'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['ju1_confirmacion'] == 0  && gruposActivos[j]['ju1'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['vi1_confirmacion'] == 0  && gruposActivos[j]['vi1'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }

          // *******************

          if( gruposActivos[j]['lu2_confirmacion'] == 0  && gruposActivos[j]['lu2'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['ma2_confirmacion'] == 0  && gruposActivos[j]['ma2'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['mi2_confirmacion'] == 0  && gruposActivos[j]['mi2'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['ju2_confirmacion'] == 0  && gruposActivos[j]['ju2'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['vi2_confirmacion'] == 0  && gruposActivos[j]['vi2'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }

          // *****************
          if( gruposActivos[j]['lu3_confirmacion'] == 0  && gruposActivos[j]['lu3'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['ma3_confirmacion'] == 0  && gruposActivos[j]['ma3'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['mi3_confirmacion'] == 0  && gruposActivos[j]['mi3'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['ju3_confirmacion'] == 0  && gruposActivos[j]['ju3'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['vi3_confirmacion'] == 0  && gruposActivos[j]['vi3'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }

          // **************
          if( gruposActivos[j]['lu4_confirmacion'] == 0  && gruposActivos[j]['lu4'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['ma4_confirmacion'] == 0  && gruposActivos[j]['ma4'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['mi4_confirmacion'] == 0  && gruposActivos[j]['mi4'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['ju4_confirmacion'] == 0  && gruposActivos[j]['ju4'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }
          if( gruposActivos[j]['vi4_confirmacion'] == 0  && gruposActivos[j]['vi4'] == id_usuario ){ this.teacherActivos[i]['estatus'] = 3 ; break  }

          this.teacherActivos[i]['estatus'] = 2
          
        }

    	}


    },

    computed:{
  		filterGrupos(){

  			let grupos = this.gruposRoles.filter( el => el.deleted == 0 ) 

  			if( this.opcion == 1 ){
  				return this.teacherActivos
  			}

  			if( this.opcion == 2 ){
  				return this.teacherActivos.filter( el => { return el.estatus == 2 })
  			}

  			if( this.opcion == 3 ){
  				return this.teacherActivos.filter( el => { return el.estatus == 3 })
  			}

  			if( this.opcion == 4 ){
  				return this.teacherActivos.filter( el => { return el.estatus == 4 })
  			}

  		}
    },

    methods:{
      cerrar(){
      	this.dialogConfirmados.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      }
    }
	}
</script>
