<template>
	<v-dialog
    v-model="dialogDobles.estatus"
    hide-overlay
    persistent
    max-width="1400"
  >
    <v-card>
      <v-card-title class="text-subtitle-1">
        VALIDACIÓN HORAS DOBLES
      </v-card-title>
      <v-card-text>

      	<v-row class="mb-4">
      		<v-col cols="12" md="6" lg="3">
	        	<v-select
	        		:items="['lunes-1','martes-1','miercoles-1','jueves-1','viernes-1','sabado-1','domingo-1','lunes-2','martes-2','miercoles-2','jueves-2','viernes-2','sabado-2','domingo-2','lunes-3','martes-3','miercoles-3','jueves-3','viernes-3','sabado-3','domingo-3','lunes-4','martes-4','miercoles-4','jueves-4','viernes-4','sabado-4','domingo-4']"
		        	label="Día"
	        	  dense
	        	  hide-details
	        	  v-model="diaSeleccionado"
	        	  clearable
	        	  single-line
	        	  filled
	        	></v-select>
      		</v-col>
      	</v-row>

  			<v-data-table
          :headers="headers"
          :items="filterGrupos"
          class="elevation-0"
          dense
          :search="search"
        >

        	<template v-slot:item.tipo="props">
		        <v-edit-dialog
		          :return-value.sync="props.item.tipo"
		          @save="save(props.item)"
		          large
		          save-text="Guardar"
		          cancel-text="Cancelar"
		        >
		          <span v-if="props.item.tipo == 2 " type="button">Doble</span>
		          <span v-if="props.item.tipo == 1 " type="button">Normal</span>
		          <span class="red--text" v-if="!props.item.tipo" type="button">Click</span>

		          <template v-slot:input>
		          	Tipo
		          	<v-radio-group
						      v-model="props.item.tipo"
						      row
						    >
						      <v-radio
						        label="Doble"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="Normal"
						        :value="1"
						      ></v-radio>
						    </v-radio-group>
		          </template>
		        </v-edit-dialog>
		      </template>
        </v-data-table>

      </v-card-text>
      <v-card-actions>
      	<v-btn
          color="black"
          tile
          dark
          small
          @click="dialogDobles.estatus = false"
        >
        	<v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>

      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                      from '@/components/alertas/Alerta.vue'
  import carga                       from '@/components/alertas/carga.vue';

  import validarErrores              from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel              from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogDobles',
			'rolReemplazo',
			'gruposRoles',
      'pagos',
      'rolRegistrado',
      'horasDobles'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      headers: [
        { text: 'Grupo',         value: 'grupo'        },
        { text: 'Faltó',         value: 'teacher'      },  
        { text: 'Tipo',          value: 'tipo'         },  
        { text: 'Notas',         value: 'notas'        },  
        { text: 'Horas',         value: 'horas_f'      },  
        { text: 'Valor hora',    value: 'valor_hora_f' },  
        { text: 'Monto',         value: 'monto'        },  
        { text: 'Reemplazó',     value: 'cubrio'       },  
        { text: 'Valor hora',    value: 'valor_hora_c' },  
        { text: 'Monto',         value: 'monto_c'      },  
      ],
      search:'',
      curso: null,
      opcion: 1,
      diaSeleccionado: '',

    }),

    computed: {
    	filterGrupos( ){

	      if( this.diaSeleccionado ){

	        const splitDia = this.diaSeleccionado.split('-')

	        const dia = splitDia[0]

	        const numerDia = splitDia[1]

	        const dosLetras = dia.substring(0,2)

	        if( dia == 'sabado' || dia == 'domingo' ){
	          
	          let data = this.rolReemplazo.filter( el => { return el[`${dia}`] == 1 })

	          let respuesta = []

	          let textoColumna = 'lu'+numerDia

	          for( const i in data ){

	          	const { idgrupo } = data[i]

	          	// 1-. sacar a quién cubrieron
	          	const existeRolOficial = this.rolRegistrado.find( el => el.idgrupo == idgrupo )
	          	
	          	let falto    = existeRolOficial ? existeRolOficial[`${textoColumna}_n`] ? existeRolOficial[`${textoColumna}_n`] : 'SIN TEACHER' : 'SIN TEACHER'
	          	let idfalto  = existeRolOficial ? existeRolOficial[`${textoColumna}`]   : 0

	          	// Consultar el salario de quién faltó por que se le va a rebajar
	          	const existeSalarioCubrio = this.pagos.find( el => el.id_usuario == idfalto )
	          	let valor_hora_f = existeSalarioCubrio ? existeSalarioCubrio.hora_1 : 0

	          	// buscar el grupo para sacar las horas que tiene por clase
	          	const existeGrupo = this.gruposRoles.find( el => el.iderp == idgrupo )
	          	let horas        = existeGrupo   ? existeGrupo.horas_dia : 0
	          	
	          	// Monto a rebajar
	          	let monto        = 1 * valor_hora_f

	          	// Ver el Salario de quién reemplazo al faltista
	          	const existeSalario = this.pagos.find( el => el.id_usuario == data[i][`${textoColumna}`] )

	          	let valor_hora_c = existeSalario ? existeSalario.hora_1 : 0

	          	let monto_c      = 1 * valor_hora_c

	          	// Hay que ver si existe un registro
	          	const existeRegistro = this.horasDobles.find( el => el.idgrupo == idgrupo && el.bloque == textoColumna && el.id_teacher_falto == idfalto )

	          	respuesta.push({
	          		idgrupo     : idgrupo,
	          		grupo       : data[i].grupo,
	          		id_teacher  : idfalto,
	          		teacher     : falto,
	          		bloque      : textoColumna,
	          		horas_f     : 1,
	          		valor_hora_f: existeRegistro ? existeRegistro.valor_hora_falto  : valor_hora_f,
	          		monto       : existeRegistro ? existeRegistro.monto_rebaje      : monto, 
	          		cubrio      : data[i][`${textoColumna}_n`] ? data[i][`${textoColumna}_n`] : 'SIN TEACHER',
	          		idcubrio    : data[i][`${textoColumna}`],
	          		valor_hora_c: existeRegistro ? existeRegistro.valor_hora_reemplazo  : valor_hora_c,
	          		monto_c     : existeRegistro ? existeRegistro.monto_pago : monto_c,
	          		tipo        :  existeRegistro ? existeRegistro.tipo : null
	          	})

	          }

	          textoColumna = 'ma'+numerDia
	          for( const i in data ){

	          	const { idgrupo } = data[i]

	          	// 1-. sacar a quién cubrieron
	          	const existeRolOficial = this.rolRegistrado.find( el => el.idgrupo == idgrupo )
	          	
	          	let falto    = existeRolOficial ? existeRolOficial[`${textoColumna}_n`] ? existeRolOficial[`${textoColumna}_n`] : 'SIN TEACHER' : 'SIN TEACHER'
	          	let idfalto  = existeRolOficial ? existeRolOficial[`${textoColumna}`]   : 0

	          	// Consultar el salario de quién faltó por que se le va a rebajar
	          	const existeSalarioCubrio = this.pagos.find( el => el.id_usuario == idfalto )
	          	let valor_hora_f = existeSalarioCubrio ? existeSalarioCubrio.hora_1 : 0

	          	// buscar el grupo para sacar las horas que tiene por clase
	          	const existeGrupo = this.gruposRoles.find( el => el.iderp == idgrupo )
	          	let horas        = existeGrupo   ? existeGrupo.horas_dia : 0
	          	
	          	// Monto a rebajar
	          	let monto        = 1 * valor_hora_f

	          	// Ver el Salario de quién reemplazo al faltista
	          	const existeSalario = this.pagos.find( el => el.id_usuario == data[i][`${textoColumna}`] )

	          	let valor_hora_c = existeSalario ? existeSalario.hora_1 : 0

	          	let monto_c      = 1 * valor_hora_c

	          	// Hay que ver si existe un registro
	          	const existeRegistro = this.horasDobles.find( el => el.idgrupo == idgrupo && el.bloque == textoColumna && el.id_teacher_falto == idfalto )

	          	respuesta.push({
	          		idgrupo     : idgrupo,
	          		grupo       : data[i].grupo,
	          		id_teacher  : idfalto,
	          		teacher     : falto,
	          		bloque      : textoColumna,
	          		horas_f     : 1,
	          		valor_hora_f: existeRegistro ? existeRegistro.valor_hora_falto  : valor_hora_f,
	          		monto       : existeRegistro ? existeRegistro.monto_rebaje      : monto, 
	          		cubrio      : data[i][`${textoColumna}_n`] ? data[i][`${textoColumna}_n`] : 'SIN TEACHER',
	          		idcubrio    : data[i][`${textoColumna}`],
	          		valor_hora_c: existeRegistro ? existeRegistro.valor_hora_reemplazo  : valor_hora_c,
	          		monto_c     : existeRegistro ? existeRegistro.monto_pago : monto_c,
	          		tipo:  existeRegistro ? existeRegistro.tipo : null
	          	})

	          }


	          textoColumna = 'mi'+numerDia
	          for( const i in data ){

	          	const { idgrupo } = data[i]

	          	// 1-. sacar a quién cubrieron
	          	const existeRolOficial = this.rolRegistrado.find( el => el.idgrupo == idgrupo )
	          	
	          	let falto    = existeRolOficial ? existeRolOficial[`${textoColumna}_n`] ? existeRolOficial[`${textoColumna}_n`] : 'SIN TEACHER' : 'SIN TEACHER'
	          	let idfalto  = existeRolOficial ? existeRolOficial[`${textoColumna}`]   : 0

	          	// Consultar el salario de quién faltó por que se le va a rebajar
	          	const existeSalarioCubrio = this.pagos.find( el => el.id_usuario == idfalto )
	          	let valor_hora_f = existeSalarioCubrio ? existeSalarioCubrio.hora_1 : 0

	          	// buscar el grupo para sacar las horas que tiene por clase
	          	const existeGrupo = this.gruposRoles.find( el => el.iderp == idgrupo )
	          	let horas        = existeGrupo   ? existeGrupo.horas_dia : 0
	          	
	          	// Monto a rebajar
	          	let monto        = 1 * valor_hora_f

	          	// Ver el Salario de quién reemplazo al faltista
	          	const existeSalario = this.pagos.find( el => el.id_usuario == data[i][`${textoColumna}`] )

	          	let valor_hora_c = existeSalario ? existeSalario.hora_1 : 0

	          	let monto_c      = 1 * valor_hora_c

	          	// Hay que ver si existe un registro
	          	const existeRegistro = this.horasDobles.find( el => el.idgrupo == idgrupo && el.bloque == textoColumna && el.id_teacher_falto == idfalto )

	          	respuesta.push({
	          		idgrupo     : idgrupo,
	          		grupo       : data[i].grupo,
	          		id_teacher  : idfalto,
	          		teacher     : falto,
	          		bloque      : textoColumna,
	          		horas_f     : 1,
	          		valor_hora_f: existeRegistro ? existeRegistro.valor_hora_falto  : valor_hora_f,
	          		monto       : existeRegistro ? existeRegistro.monto_rebaje      : monto, 
	          		cubrio      : data[i][`${textoColumna}_n`] ? data[i][`${textoColumna}_n`] : 'SIN TEACHER',
	          		idcubrio    : data[i][`${textoColumna}`],
	          		valor_hora_c: existeRegistro ? existeRegistro.valor_hora_reemplazo  : valor_hora_c,
	          		monto_c     : existeRegistro ? existeRegistro.monto_pago : monto_c,
	          		tipo:  existeRegistro ? existeRegistro.tipo : null
	          	})

	          }

	          textoColumna = 'ju'+numerDia
	          for( const i in data ){

	          	const { idgrupo } = data[i]

	          	// 1-. sacar a quién cubrieron
	          	const existeRolOficial = this.rolRegistrado.find( el => el.idgrupo == idgrupo )
	          	
	          	let falto    = existeRolOficial ? existeRolOficial[`${textoColumna}_n`] ? existeRolOficial[`${textoColumna}_n`] : 'SIN TEACHER' : 'SIN TEACHER'
	          	let idfalto  = existeRolOficial ? existeRolOficial[`${textoColumna}`]   : 0

	          	// Consultar el salario de quién faltó por que se le va a rebajar
	          	const existeSalarioCubrio = this.pagos.find( el => el.id_usuario == idfalto )
	          	let valor_hora_f = existeSalarioCubrio ? existeSalarioCubrio.hora_1 : 0

	          	// buscar el grupo para sacar las horas que tiene por clase
	          	const existeGrupo = this.gruposRoles.find( el => el.iderp == idgrupo )
	          	let horas        = existeGrupo   ? existeGrupo.horas_dia : 0
	          	
	          	// Monto a rebajar
	          	let monto        = 1 * valor_hora_f

	          	// Ver el Salario de quién reemplazo al faltista
	          	const existeSalario = this.pagos.find( el => el.id_usuario == data[i][`${textoColumna}`] )

	          	let valor_hora_c = existeSalario ? existeSalario.hora_1 : 0

	          	let monto_c      = 1 * valor_hora_c

	          	// Hay que ver si existe un registro
	          	const existeRegistro = this.horasDobles.find( el => el.idgrupo == idgrupo && el.bloque == textoColumna && el.id_teacher_falto == idfalto )

	          	respuesta.push({
	          		idgrupo     : idgrupo,
	          		grupo       : data[i].grupo,
	          		id_teacher  : idfalto,
	          		teacher     : falto,
	          		bloque      : textoColumna,
	          		horas_f     : 1,
	          		valor_hora_f: existeRegistro ? existeRegistro.valor_hora_falto  : valor_hora_f,
	          		monto       : existeRegistro ? existeRegistro.monto_rebaje      : monto, 
	          		cubrio      : data[i][`${textoColumna}_n`] ? data[i][`${textoColumna}_n`] : 'SIN TEACHER',
	          		idcubrio    : data[i][`${textoColumna}`],
	          		valor_hora_c: existeRegistro ? existeRegistro.valor_hora_reemplazo  : valor_hora_c,
	          		monto_c     : existeRegistro ? existeRegistro.monto_pago : monto_c,
	          		tipo:  existeRegistro ? existeRegistro.tipo : null
	          	})

	          }


	          textoColumna = 'vi'+numerDia
	          for( const i in data ){

	          	const { idgrupo } = data[i]

	          	// 1-. sacar a quién cubrieron
	          	const existeRolOficial = this.rolRegistrado.find( el => el.idgrupo == idgrupo )
	          	
	          	let falto    = existeRolOficial ? existeRolOficial[`${textoColumna}_n`] ? existeRolOficial[`${textoColumna}_n`] : 'SIN TEACHER' : 'SIN TEACHER'
	          	let idfalto  = existeRolOficial ? existeRolOficial[`${textoColumna}`]   : 0

	          	// Consultar el salario de quién faltó por que se le va a rebajar
	          	const existeSalarioCubrio = this.pagos.find( el => el.id_usuario == idfalto )
	          	let valor_hora_f = existeSalarioCubrio ? existeSalarioCubrio.hora_1 : 0

	          	// buscar el grupo para sacar las horas que tiene por clase
	          	const existeGrupo = this.gruposRoles.find( el => el.iderp == idgrupo )
	          	let horas        = existeGrupo   ? existeGrupo.horas_dia : 0
	          	
	          	// Monto a rebajar
	          	let monto        = 1 * valor_hora_f

	          	// Ver el Salario de quién reemplazo al faltista
	          	const existeSalario = this.pagos.find( el => el.id_usuario == data[i][`${textoColumna}`] )

	          	let valor_hora_c = existeSalario ? existeSalario.hora_1 : 0

	          	let monto_c      = 1 * valor_hora_c

	          	// Hay que ver si existe un registro
	          	const existeRegistro = this.horasDobles.find( el => el.idgrupo == idgrupo && el.bloque == textoColumna && el.id_teacher_falto == idfalto )

	          	respuesta.push({
	          		idgrupo     : idgrupo,
	          		grupo       : data[i].grupo,
	          		id_teacher  : idfalto,
	          		teacher     : falto,
	          		bloque      : textoColumna,
	          		horas_f     : 1,
	          		valor_hora_f: existeRegistro ? existeRegistro.valor_hora_falto  : valor_hora_f,
	          		monto       : existeRegistro ? existeRegistro.monto_rebaje      : monto, 
	          		cubrio      : data[i][`${textoColumna}_n`] ? data[i][`${textoColumna}_n`] : 'SIN TEACHER',
	          		idcubrio    : data[i][`${textoColumna}`],
	          		valor_hora_c: existeRegistro ? existeRegistro.valor_hora_reemplazo  : valor_hora_c,
	          		monto_c     : existeRegistro ? existeRegistro.monto_pago : monto_c,
	          		tipo:  existeRegistro ? existeRegistro.tipo : null
	          	})

	          }

	          return respuesta
	          // return gruposOficialesFiltrados.filter( el => { return el[`lu${numerDia}`] || el[`ma${numerDia}`] || el[`mi${numerDia}`] || el[`ju${numerDia}`] || el[`vi${numerDia}`]  })

	        }else{
	          let textoColumna = dosLetras+numerDia

	          let data = this.rolReemplazo.filter( el => { return el[`${textoColumna}`] && el[`${dia}`] == 1 }) 

	          let respuesta = []

	          for( const i in data ){

	          	const { idgrupo } = data[i]

	          	// 1-. sacar a quién cubrieron
	          	const existeRolOficial = this.rolRegistrado.find( el => el.idgrupo == idgrupo )
	          	
	          	let falto    = existeRolOficial ? existeRolOficial[`${textoColumna}_n`] ? existeRolOficial[`${textoColumna}_n`] : 'SIN TEACHER' : 'SIN TEACHER'
	          	let idfalto  = existeRolOficial ? existeRolOficial[`${textoColumna}`]   : 0

	          	// Consultar el salario de quién faltó por que se le va a rebajar
	          	const existeSalarioCubrio = this.pagos.find( el => el.id_usuario == idfalto )
	          	let valor_hora_f = existeSalarioCubrio ? existeSalarioCubrio.hora_1 : 0

	          	// buscar el grupo para sacar las horas que tiene por clase
	          	const existeGrupo = this.gruposRoles.find( el => el.iderp == idgrupo )
	          	let horas        = existeGrupo   ? existeGrupo.horas_dia : 0
	          	
	          	// Monto a rebajar
	          	let monto        = horas * valor_hora_f
	          	

	          	// Ver el Salario de quién reemplazo al faltista
	          	const existeSalario = this.pagos.find( el => el.id_usuario == data[i][`${textoColumna}`] )

	          	let valor_hora_c = existeSalario ? existeSalario.hora_1 : 0

	          	let monto_c      = horas * valor_hora_c


	          	// Hay que ver si existe un registro
	          	const existeRegistro = this.horasDobles.find( el => el.idgrupo == idgrupo && el.bloque == textoColumna && el.id_teacher_falto == idfalto )

	          	respuesta.push({
	          		idgrupo     : idgrupo,
	          		grupo       : data[i].grupo,
	          		id_teacher  : idfalto,
	          		teacher     : falto,
	          		bloque      : textoColumna,
	          		horas_f     : horas,
	          		valor_hora_f: existeRegistro ? existeRegistro.valor_hora_falto  : valor_hora_f,
	          		monto       : existeRegistro ? existeRegistro.monto_rebaje      : monto, 
	          		cubrio      : data[i][`${textoColumna}_n`],
	          		idcubrio    : data[i][`${textoColumna}`],
	          		valor_hora_c: existeRegistro ? existeRegistro.valor_hora_reemplazo  : valor_hora_c,
	          		monto_c     : existeRegistro ? existeRegistro.monto_pago : monto_c,
	          		tipo:  existeRegistro ? existeRegistro.tipo : null
	          	})

	          }

	          return respuesta
	        }

	      }else{
	        return []
	      }
	    },
    },

    
    methods:{

      cerrar(){
      	this.dialogDobles.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){
        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      },

      save( value ){

      	if( value.tipo == 1 ){
      		value.monto = 0
      		value.monto_c = 0
      		value.valor_hora_c = 0
      		value.valor_hora_f = 0
      	}				

      	this.cargar = true
        return this.$http.post('rol.clases.dobles', value ).then(response=>{
      		this.$emit('initialize')
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      }



    }
	}
</script>
