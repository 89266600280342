<template>
	<v-dialog
    v-model="dialogNominaQuincena.estatus"
    hide-overlay
    persistent
    max-width="1400"
  >
    <v-card>
      <v-card-title primary-title class="text-subtitle-1">
        Comparativa de nómina
      </v-card-title>
      <v-card-text >

      	<div class="black--text">
      		Total de horas Dominicales: {{ totalHorasDominicales }}<br/>
      		Total de horas Lunes a Vi:  {{ totalHorasLV }}<br/>
      		Total de horas:             {{ totalHoras }}
      	</div>
      	<br/>
      	<v-row>

      		<br/>
	      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
	          <v-text-field
	            v-model="search"
	            dense
	            filled
	            single-line
	            label="Buscar"
	            append-icon="mdi-magnify"
	          ></v-text-field>  
	        </v-col>
      	</v-row>


        <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab>Nómina</v-tab>
          <v-tab>Disperción Horas</v-tab>
          <v-tab>Comparativa</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
				        <v-data-table
				          :headers="headers1"
				          :items="teachers"
				          class="elevation-0"
				          dense
				          :search="search"
				        >
				        </v-data-table>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
				        <v-data-table
				          :headers="headers2"
				          :items="teachers"
				          class="elevation-0"
				          dense
				          :search="search"
				        >
				        </v-data-table>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		          	<v-row>
		          		<v-col cols="12" md="5">
		          			<label class="black--text"><b>Quincena {{ selectedHeaders - 1 > 2 ? 1 : 2 }}</b></label>
		          			<v-data-table
						          :headers="headers3"
						          :items="tabla2Comparativa"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
						        <label class="text-h6"><b>Costo por hora: {{ costoHora1 }}</b></label>
		          		</v-col>

		          		<v-col cols="12" md="7">

		          			<label class="black--text"><b>Quincena {{ selectedHeaders - 1 > 2 ? 2 : 1 }}</b></label>
		          			<v-data-table
						          :headers="headers4"
						          :items="tabla1Comparativa"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
						        <label class="text-h6"><b>Costo por hora: {{ costoHora2 }}</b></label>
		          		</v-col>
		          	</v-row>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		    </v-tabs-items>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
        	color="black"
        	dark 
        	tile
        	@click="dialogNominaQuincena.estatus = false"
        >Cancelar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogNominaQuincena',
			'prospectosCerrados',
			'gruposRoles',
			'selectedHeaders',
			'pagos',
			'horasDobles',
			'gruposRoles2'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      teachersBonos: [],

      headers3: [
        { text: 'valor hora' , value: 'valor_hora'    },
        { text: '# horas'    , value: 'numero_horas'  },  
        { text: 'Costo'      , value: 'costo'         },  
      ],

      headers1: [
        { text: 'id'              ,  value: 'id_teacher'         },
        { text: 'Teacher'         ,  value: 'teacher'            },  
        { text: 'PAGO_TOTAL'     ,  value: 'PAGO_TOTAL'        },  
      ],

      headers3: [
        { text: 'valor hora' , value: 'valor_hora'    },
        { text: '# horas'    , value: 'numero_horas'  },  
        { text: 'Costo'      , value: 'costo'         },  
      ],


      headers4: [
        { text: 'valor hora' , value: 'valor_hora'    },
        { text: '# horas'    , value: 'numero_horas'  },  
        { text: 'Costo'      , value: 'costo'         },  
        { text: 'Diferencia' , value: 'diferencia'   },  
        { text: 'Motivo'     , value: 'motivo'       },  
      ],


      headers2: [
        { text: 'id'              ,  value: 'id_teacher'               },
        { text: 'Teacher'         ,  value: 'teacher'                  },  
        { text: 'H_LV_FAST'       ,  value: 'HORAS_ENTRE_SEMANA_FAST'  },  
        { text: 'H_D_INBI'        ,  value: 'HORAS_DOMINGO_FAST'       },  
        { text: 'H_ONL_TEENS'     ,  value: 'HORAS_LMV_FAST'           },  
        { text: 'H_LV_FAST'       ,  value: 'HORAS_ENTRE_SEMANA_INBI'  },  
        { text: 'H_D_INBI'        ,  value: 'HORAS_DOMINGO_INBI'       },  
        { text: 'H_ONL_TEENS'     ,  value: 'HORAS_LMV_INBI'           },
        { text: 'TOTAL_HORAS'     ,  value: 'HORAS_TOTALES'            },  
        { text: 'Costo H LV'      ,  value: 'categoria_lv'             }, 
        { text: 'Costo H D'       ,  value: 'categoria_d'              }, 
        { text: 'PAGO_TOTAL'      ,  value: 'PAGO_TOTAL'               },  
      ],

      search:'',
      gruposActivos:[],

      tab: null,

      tabla1Comparativa: [],
      tabla2Comparativa: [],

      categorias1:[],
      categorias2:[],

      costoHora1: 0,
      costoHora2: 0,


    }),

    created(){
    	this.gruposActivos = this.gruposRoles.filter( el => { return el.deleted == 0 && !el.sabado })

    	this.limpiarGrupos()

    	this.sacarTeachers( )

    	this.sacarHorasQuincena1( )
    	
    	this.sacarTotales( )
    	
    	this.getTablaComparativa( )

    	this.getTablaComparativaAnt( )

    	this.igualarCategorias( )
    	
    	this.motivoDiferencias( )

    	this.formatMoney( )

    },

    computed:{
    	totalHoras(){
    		return this.totalHorasDominicales + this.totalHorasLV
    	},

    	totalHorasDominicales(){
    		return this.gruposActivos.filter( el => { return el.deleted == 0 && el.domingo }).map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0)
    	},

    	totalHorasLV(){
    		return this.gruposActivos.filter( el => { return el.deleted == 0 && ( !el.sabado && !el.domingo ) }).map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0)
    	},
    },

    methods:{
      cerrar(){
      	this.dialogNominaQuincena.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){
        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      },

      limpiarGrupos ( ) {
      	for( const i in this.gruposActivos ){
	    		const { grupo } = this.gruposActivos[i]
	      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
	    			this.gruposActivos[i].ju1_n = null
	    			this.gruposActivos[i].ju1   = null
	    			this.gruposActivos[i].vi1_n = null
	    			this.gruposActivos[i].vi1   = null
	    			this.gruposActivos[i].ju2_n = null
	    			this.gruposActivos[i].ju2   = null
	    			this.gruposActivos[i].vi2_n = null
	    			this.gruposActivos[i].vi2   = null
	    			this.gruposActivos[i].ju3_n = null
	    			this.gruposActivos[i].ju3   = null
	    			this.gruposActivos[i].vi3_n = null
	    			this.gruposActivos[i].vi3   = null
	    			this.gruposActivos[i].ju4_n = null
	    			this.gruposActivos[i].ju4   = null
	    			this.gruposActivos[i].vi4_n = null
	    			this.gruposActivos[i].vi4   = null
	    		}      		
	    	}
      },

      sacarTeachers ( ) {

      	let bloqueInicial = [3,4].includes( this.selectedHeaders ) ? 1 : 3
      	let bloqueFinal   = [3,4].includes( this.selectedHeaders ) ? 2 : 4

	      // Vamos a sacar los teachers de cada bloque
        let tlu1 = this.gruposActivos.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`lu${bloqueInicial}`], teacher: registro[`lu${bloqueInicial}_n`]  }})

        let tma1 = this.gruposActivos.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ma${bloqueInicial}`], teacher: registro[`ma${bloqueInicial}_n`]  }})

        let tmi1 = this.gruposActivos.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`mi${bloqueInicial}`], teacher: registro[`mi${bloqueInicial}_n`]  }})

        let tju1 = this.gruposActivos.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ju${bloqueInicial}`], teacher: registro[`ju${bloqueInicial}_n`]  }})

        let tvi1 = this.gruposActivos.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`vi${bloqueInicial}`], teacher: registro[`vi${bloqueInicial}_n`]  }})
        // Vamos a sacar los teachers de cada bloque
        let tlu2 = this.gruposActivos.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`lu${bloqueFinal}`], teacher: registro[`lu${bloqueFinal}_n`]  }})

        let tma2 = this.gruposActivos.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ma${bloqueFinal}`], teacher: registro[`ma${bloqueFinal}_n`]  }})

        let tmi2 = this.gruposActivos.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`mi${bloqueFinal}`], teacher: registro[`mi${bloqueFinal}_n`]  }})

        let tju2 = this.gruposActivos.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ju${bloqueFinal}`], teacher: registro[`ju${bloqueFinal}_n`]  }})

        let tvi2 = this.gruposActivos.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`vi${bloqueFinal}`], teacher: registro[`vi${bloqueFinal}_n`]  }})

        // Concatenamos a los maestros
        let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 ).concat(tlu2).concat( tma2 ).concat( tmi2 ).concat( tju2 ).concat( tvi2 )

        // Quitamos los duplicados de los maestros
        this.teachers = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);
      },

      sacarHorasQuincena1 ( ) {

      	let semana = this.selectedHeaders - 1 == -1 ? 4  : this.selectedHeaders - 1 

      	let clasesDobles = this.horasDobles.filter( el => el.tipo == 2 )

      	let bloqueInicial = [1,2].includes( semana ) ? 1 : 3
      	let bloqueFinal   = [1,2].includes( semana ) ? 2 : 4

				// Recorremos los maestros para ir agregando las cosas
				for( const i in this.teachers ){

					// Sacamos el id del teacher
					const { id_teacher, teacher } = this.teachers[i]

					// Sacamos las clases que le pertenecen al teacher 
					const clasesTeacher = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																		.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher) || ['lu', 'ma', 'mi', 'ju', 'vi']
																		.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher)) && ( !el.domingo && !el.sabado && el.horas_dia != 1.5 ));

					// SACAR LAS CLASES DOMINICALES
					const clasesTeacherDomingo = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.domingo );

					// SACAR LAS CLASES SABATINO
					const clasesTeacherSabado = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.sabado );

					// CLASES DE 1.5 HORAS
					const clasesLMV = this.gruposActivos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.horas_dia == 1.5 );

					// Lo separamos por escuela
					const clasesFast = clasesTeacher.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbi = clasesTeacher.filter( el => { return el.id_unidad_negocio == 1})

					// CLASES FAST DOMINGO
					const clasesFastDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbiDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 1})

					// CLASES FAST SABADO
					const clasesFastSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbiSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 1})

					// CLASES LMV
					const clasesFastLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbiLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 1})

					let clasesFastLV = 0
					let clasesFastLM = 0
					let clasesFastDom = 0
					let clasesInbiLV = 0
					let clasesInbiLM = 0
					let clasesInbiDom = 0


					// CONTADOR ENTRE SEMANA
					let contadorBloquesFast = 0
					for( const i in clasesFast ){

						// ENTRE SEMANA
						contadorBloquesFast += clasesFast[i][`lu${bloqueInicial}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ma${bloqueInicial}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`mi${bloqueInicial}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ju${bloqueInicial}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`vi${bloqueInicial}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0

						// ENTRE SEMANA
						contadorBloquesFast += clasesFast[i][`lu${bloqueFinal}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ma${bloqueFinal}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`mi${bloqueFinal}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ju${bloqueFinal}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`vi${bloqueFinal}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0
					}

					clasesFastLV += contadorBloquesFast

					// CONTADOR FAST DOMINGOS
					let contadorBloquesFastDomingo = 0
					for( const i in clasesFastDomingo ){

						// DOMINICALES
						contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0

						// DOMINICALES
						clasesFastDom += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						clasesFastDom += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0
					}

					// CONTADOR CLASES DE 1.5 HORAS
					let contadorBloquesFastLMV = 0
					for( const i in clasesFastLMV ){

						// ENTRE SEMANA
						contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

						contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

					}

					// INBIIIIIIIIIII CLASES ENTRE SEMANA
					// CONTADOR ENTRE SEMANA
					let contadorBloquesINBI = 0
					for( const i in clasesInbi ){

						// ENTRE SEMANA BOQUE 1 o 3
						contadorBloquesINBI += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0
						// ENTRE SEMANA BOQUE 2 o 4
						contadorBloquesINBI += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0

						// ENTRE SEMANA BOQUE 1 o 3
						clasesInbiLV += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0
						// ENTRE SEMANA BOQUE 2 o 4
						clasesInbiLV += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0

					}

					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiDomingo = 0
					for( const i in clasesInbiDomingo ){

						// DOMINICALES
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

						clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

						clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

					}

					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiLMV = 0
					for( const i in clasesInbiLMV ){

						// ENTRE SEMANA
						contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0

						contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0


						clasesInbiLM += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0

						clasesInbiLM += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0
					}


					const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

					let hora_1 = existeCategoria ? existeCategoria.hora_1 : 0
					let hora_2 = existeCategoria ? existeCategoria.hora_2 : 0
					let hora_3 = existeCategoria ? existeCategoria.hora_3 : 0



					// AGREGAREMOS LOS PAGOS
					this.teachers[i]['categoria_lv']  = hora_1
					this.teachers[i]['categoria_d']   = hora_3

					/***********************************************************************/
					this.teachers[i]['HORAS_ENTRE_SEMANA_FAST']   = contadorBloquesFast
					this.teachers[i]['HORAS_DOMINGO_FAST']        = contadorBloquesFastDomingo
					this.teachers[i]['HORAS_LMV_FAST']            = contadorBloquesFastLMV

					/************************************************************************/
					this.teachers[i]['HORAS_ENTRE_SEMANA_INBI']   = contadorBloquesINBI
					this.teachers[i]['HORAS_DOMINGO_INBI']        = contadorBloquesInbiDomingo
					this.teachers[i]['HORAS_LMV_INBI']            = contadorBloquesInbiLMV
					/************************************************************************/
					this.teachers[i]['HORAS_TOTALES']                = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV 
					this.teachers[i]['HORAS_TOTALES_LV']             = contadorBloquesFast + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiLMV 
					this.teachers[i]['HORAS_TOTALES_D']              = contadorBloquesFastDomingo + contadorBloquesInbiDomingo 
					this.teachers[i]['PAGO_TOTAL']                = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastLMV * hora_1 ) + ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_1 ) 

					/************************************************************************/

					// CLASES DOBLES
					const pagosDoblesTeachers = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher);

					const rebajesDobles = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher);

					let pagoDoble = pagosDoblesTeachers.map(item => item.monto_pago).reduce((prev, curr) => prev + curr, 0)
					let rebajeDoble = rebajesDobles.map(item => item.monto_rebaje).reduce((prev, curr) => prev + curr, 0)
					
					this.teachers[i]['PAGO_DOBLES'] = pagoDoble
					this.teachers[i]['REBAJES']     = rebajeDoble

					/************************************************************************/
				}
      },

      sacarTotales ( ){

      	this.teachers.push({
      		id_teacher: 100000,
	        teacher   : 'TOTAL',
	        HORAS_ENTRE_SEMANA_FAST  : this.teachers.map(item => item.HORAS_ENTRE_SEMANA_FAST).reduce((prev, curr) => prev + curr, 0),
	        HORAS_DOMINGO_FAST       : this.teachers.map(item => item.HORAS_DOMINGO_FAST).reduce((prev, curr) => prev + curr, 0),
	        HORAS_LMV_FAST           : this.teachers.map(item => item.HORAS_LMV_FAST).reduce((prev, curr) => prev + curr, 0),
	        HORAS_ENTRE_SEMANA_INBI  : this.teachers.map(item => item.HORAS_ENTRE_SEMANA_INBI).reduce((prev, curr) => prev + curr, 0),
	        HORAS_DOMINGO_INBI       : this.teachers.map(item => item.HORAS_DOMINGO_INBI).reduce((prev, curr) => prev + curr, 0),
	        HORAS_LMV_INBI           : this.teachers.map(item => item.HORAS_LMV_INBI).reduce((prev, curr) => prev + curr, 0),
	        HORAS_TOTALES            : this.teachers.map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0),
	        PAGO_TOTAL               : this.teachers.map(item => item.PAGO_TOTAL).reduce((prev, curr) => prev + curr, 0),
      	})
      },

      formatMoney ( ) {

      	for( const i in this.teachers ){

      		const { HORAS_ENTRE_SEMANA_FAST, HORAS_DOMINGO_FAST, HORAS_LMV_FAST, HORAS_ENTRE_SEMANA_INBI, HORAS_DOMINGO_INBI, HORAS_LMV_INBI, PAGO_TOTAL } = this.teachers[i]

      		this.teachers[i].PAGO_TOTAL       = this.format( PAGO_TOTAL )
      		this.teachers[i]['categoria_lv']  = this.format( this.teachers[i]['categoria_lv'] )
      		this.teachers[i]['categoria_d']   = this.format( this.teachers[i]['categoria_d'] )

      	}
      },

      getTablaComparativa( ){

      	// Mi variable de la tabla 1 comparativa = tabla1Comparativa

      	// Primero, sacar la semana anterior
      	let getCategoriasLv = this.teachers.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria_lv })
      	let getCategoriasD  = this.teachers.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria_d })

      	this.categorias1 = [...new Set(getCategoriasLv.concat( getCategoriasD ))].sort((a, b) => b - a) 

      	for( const i in this.categorias1 ){

      		let teachersCategoriaLv = this.teachers.filter( el => el.categoria_lv == this.categorias1[i] && el.id_teacher < 100000 )
      		let teachersCategoriaD  = this.teachers.filter( el => el.categoria_d == this.categorias1[i] && el.id_teacher < 100000 )


      		let totalHorasLv =  teachersCategoriaLv.map(item => item.HORAS_TOTALES_LV ).reduce((prev, curr) => prev + curr, 0)
      		let totalHorasD  =  teachersCategoriaD.map(item => item.HORAS_TOTALES_D ).reduce((prev, curr) => prev + curr, 0)

      		let totalHoras = totalHorasLv + totalHorasD

      		this.tabla1Comparativa.push({
      			valor_hora   : this.categorias1[i],
      			numero_horas : totalHoras,
      			costo        : this.format( totalHoras * this.categorias1[i] )
      		})

      	}

      	this.tabla1Comparativa.push({
    			valor_hora   : 'Horas totales',
    			numero_horas : this.gruposActivos.filter( el => { return el.deleted == 0 }).map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0),
    			costo        : ''
    		})

    		this.tabla1Comparativa.push({
    			valor_hora   : 'Horas impartidas',
    			numero_horas : this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0),
    			costo        : this.format( this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_TOTAL).reduce((prev, curr) => prev + curr, 0))
    		})

    		let horasImpartidas = this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0)
    		let costoTotal      = this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_TOTAL).reduce((prev, curr) => prev + curr, 0)


    		this.costoHora2     = this.format( costoTotal / horasImpartidas )
      },

      getTablaComparativaAnt( ){

      	let grupos = this.gruposRoles2.filter( el => { return el.deleted == 0 && !el.sabado })

      	// Limpiar los grupos de los teens
      	for( const i in grupos ){
	    		const { grupo } = grupos[i]
	      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
	    			grupos[i].ju1_n = null
	    			grupos[i].ju1   = null
	    			grupos[i].vi1_n = null
	    			grupos[i].vi1   = null
	    			grupos[i].ju2_n = null
	    			grupos[i].ju2   = null
	    			grupos[i].vi2_n = null
	    			grupos[i].vi2   = null
	    			grupos[i].ju3_n = null
	    			grupos[i].ju3   = null
	    			grupos[i].vi3_n = null
	    			grupos[i].vi3   = null
	    			grupos[i].ju4_n = null
	    			grupos[i].ju4   = null
	    			grupos[i].vi4_n = null
	    			grupos[i].vi4   = null
	    		}      		
	    	}

      	let clasesDobles = []

      	let bloqueInicial = [3,4].includes( this.selectedHeaders ) ? 3 : 1
      	let bloqueFinal   = [3,4].includes( this.selectedHeaders ) ? 4 : 2

	      // Vamos a sacar los teachers de cada bloque
        let tlu1 = grupos.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueInicial}_n`] })
        							.map((registro) => { return { id_teacher: registro[`lu${bloqueInicial}`], teacher: registro[`lu${bloqueInicial}_n`]  }})

        let tma1 = grupos.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueInicial}_n`] })
        							.map((registro) => { return { id_teacher: registro[`ma${bloqueInicial}`], teacher: registro[`ma${bloqueInicial}_n`]  }})

        let tmi1 = grupos.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueInicial}_n`] })
        							.map((registro) => { return { id_teacher: registro[`mi${bloqueInicial}`], teacher: registro[`mi${bloqueInicial}_n`]  }})

        let tju1 = grupos.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueInicial}_n`] })
        							.map((registro) => { return { id_teacher: registro[`ju${bloqueInicial}`], teacher: registro[`ju${bloqueInicial}_n`]  }})

        let tvi1 = grupos.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueInicial}_n`] })
        							.map((registro) => { return { id_teacher: registro[`vi${bloqueInicial}`], teacher: registro[`vi${bloqueInicial}_n`]  }})
        // Vamos a sacar los teachers de cada bloque
        let tlu2 = grupos.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueFinal}_n`] })
        							.map((registro) => { return { id_teacher: registro[`lu${bloqueFinal}`], teacher: registro[`lu${bloqueFinal}_n`]  }})

        let tma2 = grupos.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueFinal}_n`] })
        							.map((registro) => { return { id_teacher: registro[`ma${bloqueFinal}`], teacher: registro[`ma${bloqueFinal}_n`]  }})

        let tmi2 = grupos.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueFinal}_n`] })
        							.map((registro) => { return { id_teacher: registro[`mi${bloqueFinal}`], teacher: registro[`mi${bloqueFinal}_n`]  }})

        let tju2 = grupos.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueFinal}_n`] })
        							.map((registro) => { return { id_teacher: registro[`ju${bloqueFinal}`], teacher: registro[`ju${bloqueFinal}_n`]  }})

        let tvi2 = grupos.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueFinal}_n`] })
        							.map((registro) => { return { id_teacher: registro[`vi${bloqueFinal}`], teacher: registro[`vi${bloqueFinal}_n`]  }})

        // Concatenamos a los maestros
        let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 ).concat(tlu2).concat( tma2 ).concat( tmi2 ).concat( tju2 ).concat( tvi2 )

        // Quitamos los duplicados de los maestros
        let teachers = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);

				// Recorremos los maestros para ir agregando las cosas
				for( const i in teachers ){

					// Sacamos el id del teacher
					const { id_teacher, teacher } = teachers[i]

					// Sacamos las clases que le pertenecen al teacher 
					const clasesTeacher = grupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																		.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher) || ['lu', 'ma', 'mi', 'ju', 'vi']
																		.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher)) && ( !el.domingo && !el.sabado && el.horas_dia != 1.5 ));

					// SACAR LAS CLASES DOMINICALES
					const clasesTeacherDomingo = grupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.domingo );

					// SACAR LAS CLASES SABATINO
					const clasesTeacherSabado = grupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.sabado );

					// CLASES DE 1.5 HORAS
					const clasesLMV = grupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.horas_dia == 1.5 );

					// Lo separamos por escuela
					const clasesFast = clasesTeacher.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbi = clasesTeacher.filter( el => { return el.id_unidad_negocio == 1})

					// CLASES FAST DOMINGO
					const clasesFastDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbiDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 1})

					// CLASES FAST SABADO
					const clasesFastSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbiSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 1})

					// CLASES LMV
					const clasesFastLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbiLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 1})

					let clasesFastLV = 0
					let clasesFastLM = 0
					let clasesFastDom = 0
					let clasesInbiLV = 0
					let clasesInbiLM = 0
					let clasesInbiDom = 0


					// CONTADOR ENTRE SEMANA
					let contadorBloquesFast = 0
					for( const i in clasesFast ){

						// ENTRE SEMANA
						contadorBloquesFast += clasesFast[i][`lu${bloqueInicial}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ma${bloqueInicial}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`mi${bloqueInicial}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ju${bloqueInicial}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`vi${bloqueInicial}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0

						// ENTRE SEMANA
						contadorBloquesFast += clasesFast[i][`lu${bloqueFinal}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ma${bloqueFinal}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`mi${bloqueFinal}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ju${bloqueFinal}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`vi${bloqueFinal}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0
					}

					clasesFastLV += contadorBloquesFast

					// CONTADOR FAST DOMINGOS
					let contadorBloquesFastDomingo = 0
					for( const i in clasesFastDomingo ){

						// DOMINICALES
						contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0

						// DOMINICALES
						clasesFastDom += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						clasesFastDom += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0
					}

					// CONTADOR CLASES DE 1.5 HORAS
					let contadorBloquesFastLMV = 0
					for( const i in clasesFastLMV ){

						// ENTRE SEMANA
						contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

						contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0
					}

					// INBIIIIIIIIIII CLASES ENTRE SEMANA
					// CONTADOR ENTRE SEMANA
					let contadorBloquesINBI = 0
					for( const i in clasesInbi ){

						// ENTRE SEMANA BOQUE 1 o 3
						contadorBloquesINBI += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0
						// ENTRE SEMANA BOQUE 2 o 4
						contadorBloquesINBI += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0

						// ENTRE SEMANA BOQUE 1 o 3
						clasesInbiLV += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0
						// ENTRE SEMANA BOQUE 2 o 4
						clasesInbiLV += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0
					}

					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiDomingo = 0
					for( const i in clasesInbiDomingo ){

						// DOMINICALES
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

						clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

						clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0
					}


					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiLMV = 0
					for( const i in clasesInbiLMV ){

						// ENTRE SEMANA
						contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0

						contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0


						clasesInbiLM += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0

						clasesInbiLM += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0
					}


					const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

					let hora_1 = existeCategoria ? existeCategoria.hora_1 : 0
					let hora_2 = existeCategoria ? existeCategoria.hora_2 : 0
					let hora_3 = existeCategoria ? existeCategoria.hora_3 : 0

					// AGREGAREMOS LOS PAGOS
					teachers[i]['categoria_lv']  = hora_1
					teachers[i]['categoria_d']   = hora_3

					/***********************************************************************/
					teachers[i]['HORAS_ENTRE_SEMANA_FAST']   = contadorBloquesFast
					teachers[i]['HORAS_DOMINGO_FAST']        = contadorBloquesFastDomingo
					teachers[i]['HORAS_LMV_FAST']            = contadorBloquesFastLMV
					/************************************************************************/
					teachers[i]['HORAS_ENTRE_SEMANA_INBI']   = contadorBloquesINBI
					teachers[i]['HORAS_DOMINGO_INBI']        = contadorBloquesInbiDomingo
					teachers[i]['HORAS_LMV_INBI']            = contadorBloquesInbiLMV
					/************************************************************************/
					teachers[i]['HORAS_TOTALES']                = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV 
					teachers[i]['HORAS_TOTALES_LV']             = contadorBloquesFast + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiLMV 
					teachers[i]['HORAS_TOTALES_D']              = contadorBloquesFastDomingo + contadorBloquesInbiDomingo 

					teachers[i]['PAGO_TOTAL']                = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastLMV * hora_1 ) + ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_1 ) 

					/************************************************************************/
					// CLASES DOBLES
					const pagosDoblesTeachers = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher);

					const rebajesDobles = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher);

					let pagoDoble = pagosDoblesTeachers.map(item => item.monto_pago).reduce((prev, curr) => prev + curr, 0)
					let rebajeDoble = rebajesDobles.map(item => item.monto_rebaje).reduce((prev, curr) => prev + curr, 0)
					
					teachers[i]['PAGO_DOBLES'] = pagoDoble
					teachers[i]['REBAJES']     = rebajeDoble
					/************************************************************************/
				}

				console.log( teachers )

      	// Mi variable de la tabla 1 comparativa = tabla1Comparativa

      	// Primero, sacar la semana anterior
      	let getCategoriasLv = teachers.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria_lv })
      	let getCategoriasD  = teachers.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria_d })

      	this.categorias2 = [...new Set(getCategoriasLv.concat( getCategoriasD ))].sort((a, b) => b - a) 

      	for( const i in this.categorias2 ){

      		let teachersCategoriaLv = teachers.filter( el => el.categoria_lv == this.categorias2[i] && el.id_teacher < 100000 )
      		let teachersCategoriaD  = teachers.filter( el => el.categoria_d == this.categorias2[i] && el.id_teacher < 100000 )


      		let totalHorasLv =  teachersCategoriaLv.map(item => item.HORAS_TOTALES_LV ).reduce((prev, curr) => prev + curr, 0)
      		let totalHorasD  =  teachersCategoriaD.map(item => item.HORAS_TOTALES_D ).reduce((prev, curr) => prev + curr, 0)

      		let totalHoras = totalHorasLv + totalHorasD

      		this.tabla2Comparativa.push({
      			valor_hora   : this.categorias2[i],
      			numero_horas : totalHoras,
      			costo        : this.format( totalHoras * this.categorias2[i] )
      		})

      	}

      	this.tabla2Comparativa.push({
    			valor_hora   : 'Horas totales',
    			numero_horas : grupos.filter( el => { return el.deleted == 0 }).map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0),
    			costo        : ''
    		})

    		this.tabla2Comparativa.push({
    			valor_hora   : 'Horas impartidas',
    			numero_horas : teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0),
    			costo        : this.format( teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_TOTAL).reduce((prev, curr) => prev + curr, 0))
    		})

    		let horasImpartidas = teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.HORAS_TOTALES).reduce((prev, curr) => prev + curr, 0)
    		let costoTotal      = teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_TOTAL).reduce((prev, curr) => prev + curr, 0)

    		this.costoHora1     = this.format( costoTotal / horasImpartidas )

      },

      igualarCategorias( ){

      	if( this.tabla1Comparativa.length != this.tabla2Comparativa.length ){


      		if( this.tabla1Comparativa.length > this.tabla2Comparativa.length ){

      			// Función para encontrar el índice de un objeto por id
						const findIndexById = (arr, valor_hora) => arr.findIndex(obj => obj.valor_hora == valor_hora);

      			this.tabla1Comparativa.forEach((obj, index) => {
						  const foundIndex = findIndexById(this.tabla2Comparativa, obj.valor_hora);
						  if (foundIndex === -1) {
						    // Insertar el objeto en la misma posición que en array1
						    let objeto = {
						    	valor_hora: obj.valor_hora,
						    	numero_horas: 0,
						    	costo: '$0.00'
						    }
						    this.tabla2Comparativa.splice(index, 0, objeto);
						  }
						});

      		}else{

      			// Función para encontrar el índice de un objeto por id
						const findIndexById = (arr, valor_hora) => arr.findIndex(obj => obj.valor_hora == valor_hora);


						this.tabla2Comparativa.forEach((obj, index) => {
						  const foundIndex = findIndexById(this.tabla1Comparativa, obj.valor_hora);
						  if (foundIndex === -1) {
						    // Insertar el objeto en la misma posición que en array1
						    let objeto = {
						    	valor_hora: obj.valor_hora,
						    	numero_horas: 0,
						    	costo: '$0.00'
						    }
						    this.tabla1Comparativa.splice(index, 0, objeto);
						  }
						});

      		}

      	}
      },

      motivoDiferencias ( ) {

      	for( const i in this.tabla2Comparativa ){

      		const { numero_horas, valor_hora, costo } = this.tabla2Comparativa[i]

      		let diferencia = this.tabla1Comparativa[i].numero_horas - numero_horas

      		let diferenciaM = parseFloat( this.tabla1Comparativa[i].costo.replace('$','').replace(',','')) - parseFloat( costo.replace('$','').replace(',','') )

      		this.tabla1Comparativa[i]['diferencia']  = this.format( diferenciaM )

      		if( numero_horas > this.tabla1Comparativa[i].numero_horas ){
      			
      			this.tabla1Comparativa[i]['motivo'] = `Menos ${ diferencia } horas de ${ valor_hora }`

      		}else if( numero_horas == this.tabla1Comparativa[i].numero_horas ){
      			
      			this.tabla1Comparativa[i]['motivo'] = 'No hay diferencia'

      		}else{

      			this.tabla1Comparativa[i]['motivo'] = `Más ${ diferencia } horas de ${ valor_hora }`

      		}
      		// 

      	}
				// this.tabla2Comparativa

      },

      format( valor ){
			  return valor.toLocaleString('es-MX', {
			    style: 'currency',
			    currency: 'MXN'
			  });
      }

    }
	}
</script>
