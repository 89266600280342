<template>
	<!-- Dialog para ver los teacher rolados-->
  <v-dialog
    v-model="dialogRolados.estatus"
    max-width="1000px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title primary-title>
        Teacher sin rolar
      </v-card-title>
      <v-card-text>
      	<v-row class="mb-4">
      		<v-col cols="12" md="6" lg="3">
	        	<v-text-field
	        	  label="Seleccionar fecha"
	        	  dense
	        	  hide-details
	        	  v-model="fechaRol"
	        	  single-line
	        	  type="date"
	        	  filled
	        	></v-text-field>
      		</v-col>

      		<v-col cols="12" md="6" lg="3">
      			<v-autocomplete
				    	filled
				      :items="plantelesRol"
				      v-model="plantelRol"
				      label="Plantel"
				      clearable
				      dense
				      single-line
				      hide-details
				      dense
				      item-text="plantel"
				      item-value="id_plantel"
				    ></v-autocomplete>
					</v-col>

      		<v-col cols="12" md="6" lg="3">
	        	<v-text-field
	        	  label="Buscar"
	        	  dense
	        	  hide-details
	        	  v-model="search2"
	        	  single-line
	        	  filled
	        	></v-text-field>
      		</v-col>

      		<v-col cols="12" md="6" lg="3">
	        	<v-select
	        		:items="['SIN HORAIO', 'Vespertino', 'Matutino' ]"
		        	label="Disponibilidad"
	        	  dense
	        	  hide-details
	        	  v-model="horario"
	        	  clearable
	        	  single-line
	        	  filled
	        	></v-select>
      		</v-col>

      		<v-col cols="12" md="6" lg="3">
	        	<v-btn 
	        		color="green"
	        		dark
	        		@click="getRolados"
	        		small
	        		tile
	        	>
	        		Actualizar
	        	</v-btn>
      		</v-col>

      	</v-row>
        <!-- Tabla con toda la información -->
        <v-data-table
          :headers="headersRolados"
          :items="filterRolados"
          class="elevation-0"
          height="500"
          dense
          fixed-header
          :search="search2"
          multi-sort
          :loading="cargar"
        > 
        </v-data-table>
      </v-card-text>
      <v-card-actions>
      	<v-btn 
      		color="black"
      		small 
      		rounded 
      		@click="dialogRolados.estatus = false" 
      		dark
      		tile
      	>
      		<v-icon small left>mdi-cancel</v-icon>
      		Cancelar
      	</v-btn>

        <v-spacer></v-spacer>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogRolados',
			'prospectosCerrados',
			'gruposRoles',
			'teachers'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

	    fechaRol: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      teacherActivos:[],
      rolados:[],
      plantelRol: null,

      headersCerrados: [
        { text: 'Folio',          value: 'id_usuario'      },
        { text: 'Nombre',         value: 'nombre_completo' },  
        { text: 'Teléfono',       value: 'telefono1' },  
        { text: 'Sucursal',       value: 'plantel' },  
        { text: 'Disponibilidad', value: 'disponibilidad' },  
      ],
      search2:'',
      curso: null,
      plantelesRol:[],
      horario: null,
      rolados:[],
			headersRolados: [
	      { text: 'ID'           , value: 'id_usuario'       },
	      { text: 'Teacher'      , value: 'nombre_completo'  },
	      { text: 'Teléfono 1'   , value: 'telefono1'        },
	      { text: 'Teléfono 2'   , value: 'telefono2'        },
	      { text: 'Plantel'      , value: 'plantel'          },
	      { text: 'Motivo'       , value: 'MOTIVO'           },
	      { text: 'Horario'      , value: 'horario'          },
	    ],

    }),

    watch: {
      plantelRol( ){
      	if(this.plantelRol){
      		this.getRolados()
      	}
      }
    },

    async created(){
    	this.getRolados()
    	await this.getPlantelesRol()

    },

    computed:{
  		filterRolados( ){

    		if( this.horario ){
    			return this.rolados.filter( el => { return el.horario == this.horario })
    		}else{
    			return this.rolados
    		}
    	}
    },

    methods:{

    	getPlantelesRol( ){
				this.cargar = true
        this.plantelesRol = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.plantelesRol = response.data
        	console.log( this.plantelesRol )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


    	getRolados( ){
      	this.cargar      = true

      	const payload = {
      		fecha: this.fechaRol,
      		id_plantel: this.plantelRol
      	}
      	
      	this.rolados = []

        this.$http.post('rol.reemplazos', payload ).then(response=>{
        	this.rolados = response.data.teachers
        	console.log( this.rolados )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      cerrar(){
      	this.dialogNoRolados.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      }
    }
	}
</script>
