<template>
	<v-dialog
    v-model="dialogNominaSabatina.estatus"
    hide-overlay
    persistent
    max-width="1200"
  >
    <v-card>
      <v-card-title primary-title class="text-subtitle-1">
        Comparativa de nómina
      </v-card-title>
      <v-card-text >

      	<div class="black--text">
      		Total de horas:             {{ totalHoras }}
      	</div>
      	<br/>
      	<v-row>

      		<br/>
	      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
	          <v-text-field
	            v-model="search"
	            dense
	            filled
	            single-line
	            label="Buscar"
	            append-icon="mdi-magnify"
	          ></v-text-field>  
	        </v-col>
      	</v-row>


        <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab>Nómina</v-tab>
          <v-tab>Disperción Horas</v-tab>
          <v-tab>Comparativa</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
				        <v-data-table
				          :headers="headers1"
				          :items="teachers"
				          class="elevation-0"
				          dense
				          :search="search"
				        >
				        </v-data-table>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
				        <v-data-table
				          :headers="headers2"
				          :items="teachers"
				          class="elevation-0"
				          dense
				          :search="search"
				        >
				        </v-data-table>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		          	<v-row>
		          		<v-col cols="12" md="5">
		          			<label class="black--text"><b>Semana {{ selectedHeaders - 1 }}</b></label>
		          			<v-data-table
						          :headers="headers3"
						          :items="tabla2Comparativa"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
						        <label class="text-h6"><b>Costo por hora: {{ costoHora1 }}</b></label>
		          		</v-col>

		          		<v-col cols="12" md="7">

		          			<label class="black--text"><b>Semana {{ selectedHeaders }}</b></label>
		          			<v-data-table
						          :headers="headers4"
						          :items="tabla1Comparativa"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
						        <label class="text-h6"><b>Costo por hora: {{ costoHora2 }}</b></label>
		          		</v-col>
		          	</v-row>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		    </v-tabs-items>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
        	color="black"
        	dark 
        	tile
        	@click="dialogNominaSabatina.estatus = false"
        >Cancelar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogNominaSabatina',
			'prospectosCerrados',
			'gruposRoles',
			'selectedHeaders',
			'pagos',
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      teachersBonos: [],

      headers3: [
        { text: 'valor hora' , value: 'valor_hora'    },
        { text: '# horas'    , value: 'numero_horas'  },  
        { text: 'Costo'      , value: 'costo'         },  
      ],

      headers1: [
        { text: 'id'              ,  value: 'id_teacher'         },
        { text: 'Teacher'         ,  value: 'teacher'            },  
        { text: 'Plantel'         ,  value: 'plantel'            },  
        { text: 'PAGO_SABADO'     ,  value: 'PAGO_SABADO'        },  
      ],


      headers2: [
        { text: 'id'              ,  value: 'id_teacher'         },
        { text: 'Teacher'         ,  value: 'teacher'            },  
        { text: 'H_SABADO_FAST'   ,  value: 'HORAS_SABADO_FAST'  },  
        { text: 'H_SABADO_INBI'   ,  value: 'HORAS_SABADO_INBI'  },  
        { text: 'H_SABADO_TEENS'  ,  value: 'HORAS_SABADO_TEENS' },  
        { text: 'TOTAL_HORAS'     ,  value: 'TOTAL_HORAS'        },  
        { text: 'Costo H'         ,  value: 'categoria'          },  
        { text: 'PAGO_SABADO'     ,  value: 'PAGO_SABADO'        },  
      ],

      headers4: [
        { text: 'valor hora' , value: 'valor_hora'    },
        { text: '# horas'    , value: 'numero_horas'  },  
        { text: 'Costo'      , value: 'costo'         },  
        { text: 'Diferencia' , value: 'diferencia'   },  
        { text: 'Motivo'     , value: 'motivo'       },  
      ],

      search:'',
      gruposActivos:[],

      tab: null,

      tabla1Comparativa: [],
      tabla2Comparativa: [],

      categorias1:[],
      categorias2:[],

      costoHora1: 0,
      costoHora2: 0,


    }),

    created(){
    	this.gruposActivos = this.gruposRoles.filter( el => { return el.deleted == 0 && el.sabado })

    	this.limpiarGrupos()

    	this.sacarTeachers( )

    	this.sacarHorasSabado1( )
    	
    	this.sacarTotales( )
    	
    	this.getTablaComparativa( )

    	this.getTablaComparativaAnt( )

    	this.igualarCategorias( )

    	this.motivoDiferencias( )

    	this.formatMoney( )

    },

    computed:{
    	totalHoras(){
    		return this.gruposActivos.filter( el => { return el.deleted == 0 && el.sabado }).map(item => item.horas_semana ).reduce((prev, curr) => prev + curr, 0)
    	},
    },

    methods:{
      cerrar(){
      	this.dialogNominaSabatina.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){
        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      },

      limpiarGrupos ( ) {
      	for( const i in this.gruposActivos ){
	    		const { grupo } = this.gruposActivos[i]
	      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
	    			this.gruposActivos[i].ju1_n = null
	    			this.gruposActivos[i].ju1   = null
	    			this.gruposActivos[i].vi1_n = null
	    			this.gruposActivos[i].vi1   = null
	    			this.gruposActivos[i].ju2_n = null
	    			this.gruposActivos[i].ju2   = null
	    			this.gruposActivos[i].vi2_n = null
	    			this.gruposActivos[i].vi2   = null
	    			this.gruposActivos[i].ju3_n = null
	    			this.gruposActivos[i].ju3   = null
	    			this.gruposActivos[i].vi3_n = null
	    			this.gruposActivos[i].vi3   = null
	    			this.gruposActivos[i].ju4_n = null
	    			this.gruposActivos[i].ju4   = null
	    			this.gruposActivos[i].vi4_n = null
	    			this.gruposActivos[i].vi4   = null
	    		}      		
	    	}
      },

      sacarTeachers ( ) {
      	// Sacar a los teachers que dan clase
	    	// Vamos a sacar los teachers de cada bloque
	      let tlu1 = this.gruposActivos.filter( el => { return el[`lu${this.selectedHeaders}_n`] }).map((registro) => { return { id_teacher: registro[`lu${this.selectedHeaders}`], teacher: registro[`lu${this.selectedHeaders}_n`]}})
	      let tma1 = this.gruposActivos.filter( el => { return el[`ma${this.selectedHeaders}_n`] }).map((registro) => { return { id_teacher: registro[`ma${this.selectedHeaders}`], teacher: registro[`ma${this.selectedHeaders}_n`]}})
	      let tmi1 = this.gruposActivos.filter( el => { return el[`mi${this.selectedHeaders}_n`] }).map((registro) => { return { id_teacher: registro[`mi${this.selectedHeaders}`], teacher: registro[`mi${this.selectedHeaders}_n`]}})
	      let tju1 = this.gruposActivos.filter( el => { return el[`ju${this.selectedHeaders}_n`] }).map((registro) => { return { id_teacher: registro[`ju${this.selectedHeaders}`], teacher: registro[`ju${this.selectedHeaders}_n`]}})
	      let tvi1 = this.gruposActivos.filter( el => { return el[`vi${this.selectedHeaders}_n`] }).map((registro) => { return { id_teacher: registro[`vi${this.selectedHeaders}`], teacher: registro[`vi${this.selectedHeaders}_n`]}})

	      // Concatenamos a los maestros
	      let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 )
	        
	      // Quitamos los duplicados de los maestros
	      this.teachers = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);
      },

      sacarHorasSabado1 ( ) {

				// Recorremos los maestros para ir agregando las cosas
				for( const i in this.teachers ){

					// Sacamos el id del teacher
					const { id_teacher } = this.teachers[i]

					// Sacamos las clases donde esta el maestro 
					let clasesSabado = this.gruposActivos.filter( el => el[`lu${this.selectedHeaders}`] == id_teacher || el[`ma${this.selectedHeaders}`] == id_teacher || el[`mi${this.selectedHeaders}`] == id_teacher || el[`ju${this.selectedHeaders}`] == id_teacher || el[`vi${this.selectedHeaders}`] == id_teacher )

					// Lo separamos por escuela
					const clasesFast      = clasesSabado.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbi      = clasesSabado.filter( el => { return el.id_unidad_negocio == 1})
					const clasesInbiTeens = clasesSabado.filter( el => { return el.id_unidad_negocio == 1})

					let clasesInbiContador = 0
					let clasesFastContador = 0

					let contadorBloquesFast = 0
					for( const i in clasesFast ){

						contadorBloquesFast += clasesFast[i][`lu${this.selectedHeaders}`] == id_teacher ? 1 : 0
						contadorBloquesFast += clasesFast[i][`ma${this.selectedHeaders}`] == id_teacher ? 1 : 0
						contadorBloquesFast += clasesFast[i][`mi${this.selectedHeaders}`] == id_teacher ? 1 : 0
						contadorBloquesFast += clasesFast[i][`ju${this.selectedHeaders}`] == id_teacher ? 1 : 0
						contadorBloquesFast += clasesFast[i][`vi${this.selectedHeaders}`] == id_teacher ? 1 : 0

						clasesFastContador += clasesFast[i][`lu${this.selectedHeaders}`] == id_teacher ? 1 : 0
					}

					let contadorBloquesInbi = 0
					for( const i in clasesInbi ){

						const { grupo } = clasesInbi[i]

						if( grupo.match('TEENS') ){

						}else{
							contadorBloquesInbi += clasesInbi[i][`lu${this.selectedHeaders}`] == id_teacher ? 1 : 0
							contadorBloquesInbi += clasesInbi[i][`ma${this.selectedHeaders}`] == id_teacher ? 1 : 0
							contadorBloquesInbi += clasesInbi[i][`mi${this.selectedHeaders}`] == id_teacher ? 1 : 0
							contadorBloquesInbi += clasesInbi[i][`ju${this.selectedHeaders}`] == id_teacher ? 1 : 0
							contadorBloquesInbi += clasesInbi[i][`vi${this.selectedHeaders}`] == id_teacher ? 1 : 0

							clasesInbiContador += clasesInbi[i][`lu${this.selectedHeaders}`] == id_teacher ? 1 : 0
						}
					}

					// CLASES TEENS
					let contadorBloqueTeens = 0
					let clasesTeens = clasesInbi.filter( el => { return el.grupo.match('TEENS') })

					for( const i in clasesTeens ){
						const { grupo } = clasesTeens[i]
						contadorBloqueTeens += 3
						clasesInbiContador  += 1
					}

					const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

					let hora_1 = existeCategoria ? existeCategoria.hora_1 : 0
					let hora_2 = existeCategoria ? existeCategoria.hora_2 : 0
					let hora_3 = existeCategoria ? existeCategoria.hora_3 : 0

					// AGREGAREMOS LOS PAGOS
					this.teachers[i]['categoria']  = hora_2

					const total_horas = contadorBloquesFast + contadorBloquesInbi + contadorBloqueTeens

					let totalClases = this.gruposActivos.filter( el => el[`lu${this.selectedHeaders}`] == id_teacher || el[`ma${this.selectedHeaders}`] == id_teacher || el[`mi${this.selectedHeaders}`] == id_teacher || el[`ju${this.selectedHeaders}`] == id_teacher || el[`vi${this.selectedHeaders}`] == id_teacher ).length

					let clasesTeacher = this.gruposActivos.filter( el => el[`lu${this.selectedHeaders}`] == id_teacher || el[`ma${this.selectedHeaders}`] == id_teacher || el[`mi${this.selectedHeaders}`] == id_teacher || el[`ju${this.selectedHeaders}`] == id_teacher || el[`vi${this.selectedHeaders}`] == id_teacher )

					this.teachers[i]['CLASES_SABADO_FAST']  = clasesFast.length
					this.teachers[i]['HORAS_SABADO_FAST']   = contadorBloquesFast
					this.teachers[i]['CLASES_SABADO_INBI']  = clasesInbi.length
					this.teachers[i]['HORAS_SABADO_INBI']   = contadorBloquesInbi
					this.teachers[i]['CLASES_SABADO_TEENS'] = clasesTeens.length
					this.teachers[i]['HORAS_SABADO_TEENS']  = contadorBloqueTeens
					this.teachers[i]['TOTAL_CLASES']        = totalClases
					this.teachers[i]['TOTAL_HORAS']         = contadorBloquesFast + contadorBloquesInbi + contadorBloqueTeens

					this.teachers[i]['PAGO_SABADO']         = ( contadorBloquesInbi * hora_2 ) + ( contadorBloquesFast * hora_2 ) + ( contadorBloqueTeens * hora_2 )
					this.teachers[i]['plantel']             = clasesSabado.length ? clasesSabado[0].plantel : 'SIN PLANTEL'

				}
      },

      sacarTotales ( ){

      	this.teachers.push({
      		id_teacher: 100000,
	        teacher   : 'TOTAL',
	        HORAS_SABADO_FAST  : this.teachers.map(item => item.HORAS_SABADO_FAST).reduce((prev, curr) => prev + curr, 0),
	        HORAS_SABADO_INBI  : this.teachers.map(item => item.HORAS_SABADO_INBI).reduce((prev, curr) => prev + curr, 0),
	        HORAS_SABADO_TEENS : this.teachers.map(item => item.HORAS_SABADO_TEENS).reduce((prev, curr) => prev + curr, 0),
	        TOTAL_HORAS        : this.teachers.map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0),
	        PAGO_SABADO        : this.teachers.map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0),
      	})
      },

      formatMoney ( ) {

      	for( const i in this.teachers ){

      		const { HORAS_SABADO_FAST, HORAS_SABADO_INBI, HORAS_SABADO_TEENS, TOTAL_HORAS, PAGO_SABADO } = this.teachers[i]

      		this.teachers[i].PAGO_SABADO = this.format( PAGO_SABADO )
      		this.teachers[i]['categoria'] = this.format( this.teachers[i]['categoria'] )

      	}
      },

      getTablaComparativa( ){

      	// Mi variable de la tabla 1 comparativa = tabla1Comparativa

      	// Primero, sacar la semana anterior
      	let getCategorias = this.teachers.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria })

      	this.categorias1 = [...new Set(getCategorias)].sort((a, b) => b - a) 

      	for( const i in this.categorias1 ){

      		let teachersCategoria = this.teachers.filter( el => el.categoria == this.categorias1[i] && el.id_teacher < 100000 )

      		let totalHoras =  teachersCategoria.map(item => item.TOTAL_HORAS ).reduce((prev, curr) => prev + curr, 0)


      		this.tabla1Comparativa.push({
      			valor_hora   : this.categorias1[i],
      			numero_horas : totalHoras,
      			costo        : this.format( totalHoras * this.categorias1[i] )
      		})

      	}

      	this.tabla1Comparativa.push({
    			valor_hora   : 'Horas totales',
    			numero_horas : this.gruposActivos.filter( el => { return el.deleted == 0 && el.sabado }).map(item => item.horas_semana ).reduce((prev, curr) => prev + curr, 0),
    			costo        : this.format( this.teachers.filter( el => el.teacher != 'TOTAL' ).map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0))
    		})

    		this.tabla1Comparativa.push({
    			valor_hora   : 'Horas impartidas',
    			numero_horas : this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0),
    			costo        : this.format( this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0))
    		})

    		let horasImpartidas = this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0)
    		let costoTotal      = this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0)


    		this.costoHora2     = this.format( costoTotal / horasImpartidas )
      },

      getTablaComparativaAnt( ){

      	let semana = this.selectedHeaders - 1 

      	// Sacar a los teachers que dan clase
	    	// Vamos a sacar los teachers de cada bloque
	      let tlu1 = this.gruposActivos.filter( el => { return el[`lu${semana}_n`] }).map((registro) => { return { id_teacher: registro[`lu${semana}`], teacher: registro[`lu${semana}_n`]}})
	      let tma1 = this.gruposActivos.filter( el => { return el[`ma${semana}_n`] }).map((registro) => { return { id_teacher: registro[`ma${semana}`], teacher: registro[`ma${semana}_n`]}})
	      let tmi1 = this.gruposActivos.filter( el => { return el[`mi${semana}_n`] }).map((registro) => { return { id_teacher: registro[`mi${semana}`], teacher: registro[`mi${semana}_n`]}})
	      let tju1 = this.gruposActivos.filter( el => { return el[`ju${semana}_n`] }).map((registro) => { return { id_teacher: registro[`ju${semana}`], teacher: registro[`ju${semana}_n`]}})
	      let tvi1 = this.gruposActivos.filter( el => { return el[`vi${semana}_n`] }).map((registro) => { return { id_teacher: registro[`vi${semana}`], teacher: registro[`vi${semana}_n`]}})

	      // Concatenamos a los maestros
	      let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 )
	        
	      // Quitamos los duplicados de los maestros
	      let teachers = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);


	      // Recorremos los maestros para ir agregando las cosas
				for( const i in teachers ){

					// Sacamos el id del teacher
					const { id_teacher } = teachers[i]

					// Sacamos las clases donde esta el maestro 
					let clasesSabado = this.gruposActivos.filter( el => el[`lu${semana}`] == id_teacher || el[`ma${semana}`] == id_teacher || el[`mi${semana}`] == id_teacher || el[`ju${semana}`] == id_teacher || el[`vi${semana}`] == id_teacher )

					// Lo separamos por escuela
					const clasesFast      = clasesSabado.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbi      = clasesSabado.filter( el => { return el.id_unidad_negocio == 1})
					const clasesInbiTeens = clasesSabado.filter( el => { return el.id_unidad_negocio == 1})

					let clasesInbiContador = 0
					let clasesFastContador = 0

					let contadorBloquesFast = 0
					for( const i in clasesFast ){

						contadorBloquesFast += clasesFast[i][`lu${semana}`] == id_teacher ? 1 : 0
						contadorBloquesFast += clasesFast[i][`ma${semana}`] == id_teacher ? 1 : 0
						contadorBloquesFast += clasesFast[i][`mi${semana}`] == id_teacher ? 1 : 0
						contadorBloquesFast += clasesFast[i][`ju${semana}`] == id_teacher ? 1 : 0
						contadorBloquesFast += clasesFast[i][`vi${semana}`] == id_teacher ? 1 : 0

						clasesFastContador += clasesFast[i][`lu${semana}`] == id_teacher ? 1 : 0
					}

					let contadorBloquesInbi = 0
					for( const i in clasesInbi ){

						const { grupo } = clasesInbi[i]

						if( grupo.match('TEENS') ){

						}else{
							contadorBloquesInbi += clasesInbi[i][`lu${semana}`] == id_teacher ? 1 : 0
							contadorBloquesInbi += clasesInbi[i][`ma${semana}`] == id_teacher ? 1 : 0
							contadorBloquesInbi += clasesInbi[i][`mi${semana}`] == id_teacher ? 1 : 0
							contadorBloquesInbi += clasesInbi[i][`ju${semana}`] == id_teacher ? 1 : 0
							contadorBloquesInbi += clasesInbi[i][`vi${semana}`] == id_teacher ? 1 : 0

							clasesInbiContador += clasesInbi[i][`lu${semana}`] == id_teacher ? 1 : 0
						}
					}

					// CLASES TEENS
					let contadorBloqueTeens = 0
					let clasesTeens = clasesInbi.filter( el => { return el.grupo.match('TEENS') })

					for( const i in clasesTeens ){
						const { grupo } = clasesTeens[i]
						contadorBloqueTeens += 3
						clasesInbiContador  += 1
					}

					const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

					let hora_1 = existeCategoria ? existeCategoria.hora_1 : 0
					let hora_2 = existeCategoria ? existeCategoria.hora_2 : 0
					let hora_3 = existeCategoria ? existeCategoria.hora_3 : 0

					// AGREGAREMOS LOS PAGOS
					teachers[i]['categoria']  = hora_2

					const total_horas = contadorBloquesFast + contadorBloquesInbi + contadorBloqueTeens

					let totalClases = this.gruposActivos.filter( el => el[`lu${this.selectedHeaders}`] == id_teacher || el[`ma${this.selectedHeaders}`] == id_teacher || el[`mi${this.selectedHeaders}`] == id_teacher || el[`ju${this.selectedHeaders}`] == id_teacher || el[`vi${this.selectedHeaders}`] == id_teacher ).length

					teachers[i]['CLASES_SABADO_FAST']  = clasesFast.length
					teachers[i]['HORAS_SABADO_FAST']   = contadorBloquesFast
					teachers[i]['CLASES_SABADO_INBI']  = clasesInbi.length
					teachers[i]['HORAS_SABADO_INBI']   = contadorBloquesInbi
					teachers[i]['CLASES_SABADO_TEENS'] = clasesTeens.length
					teachers[i]['HORAS_SABADO_TEENS']  = contadorBloqueTeens
					teachers[i]['TOTAL_CLASES']        = totalClases
					teachers[i]['TOTAL_HORAS']         = contadorBloquesFast + contadorBloquesInbi + contadorBloqueTeens

					teachers[i]['PAGO_SABADO']         = ( contadorBloquesInbi * hora_2 ) + ( contadorBloquesFast * hora_2 ) + ( contadorBloqueTeens * hora_2 )

				}


      	// Mi variable de la tabla 1 comparativa = tabla1Comparativa

      	// Primero, sacar la semana anterior
      	let getCategorias = teachers.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria })

      	this.categorias2 = [...new Set(getCategorias)].sort((a, b) => b - a) 

      	for( const i in this.categorias2 ){

      		let teachersCategoria = teachers.filter( el => el.categoria == this.categorias2[i] && el.id_teacher < 100000 )

      		let totalHoras =  teachersCategoria.map(item => item.TOTAL_HORAS ).reduce((prev, curr) => prev + curr, 0)


      		this.tabla2Comparativa.push({
      			valor_hora   : this.categorias2[i],
      			numero_horas : totalHoras,
      			costo        : this.format( totalHoras * this.categorias2[i] )
      		})

      	}


      	this.tabla2Comparativa.push({
    			valor_hora   : 'Horas totales',
    			numero_horas : teachers.filter( el => el.teacher != 'TOTAL' ).map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0),
    			costo        : this.format( teachers.filter( el => el.teacher != 'TOTAL' ).map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0))
    		})

    		this.tabla2Comparativa.push({
    			valor_hora   : 'Horas impartidas',
    			numero_horas : teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0),
    			costo        : this.format( teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0))
    		})

    		let horasImpartidas = teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0)
    		let costoTotal      = teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0)

    		this.costoHora1     = this.format( costoTotal / horasImpartidas )
      },

      igualarCategorias( ){

      	if( this.tabla1Comparativa.length != this.tabla2Comparativa.length ){


      		if( this.tabla1Comparativa.length > this.tabla2Comparativa.length ){

      			for( const i in this.tabla1Comparativa ){

      				console.log( this.tabla1Comparativa )

      				// if( !this.tabla2Comparativa.find( el => el == this.tabla1Comparativa[i] ) ){

      				// 	alert(`no encontre la categoria 1: ${ this.tabla1Comparativa[i] }`)

      				// }

      			}

      		}else{

      			// Función para encontrar el índice de un objeto por id
						const findIndexById = (arr, valor_hora) => arr.findIndex(obj => obj.valor_hora == valor_hora);


						this.tabla2Comparativa.forEach((obj, index) => {
						  const foundIndex = findIndexById(this.tabla1Comparativa, obj.valor_hora);
						  if (foundIndex === -1) {
						    // Insertar el objeto en la misma posición que en array1
						    let objeto = {
						    	valor_hora: obj.valor_hora,
						    	numero_horas: 0,
						    	costo: '$0.00'
						    }
						    this.tabla1Comparativa.splice(index, 0, objeto);
						  }
						});

						console.log( 'finnn', this.tabla1Comparativa )

      			// for( const i in this.tabla2Comparativa ){

      				// if( !this.tabla1Comparativa.find( el => el.valor_hora == this.tabla2Comparativa[i].valor_hora ) ){

      				// 	// this.tabla2Comparativa.push({

      				// 	// })

      				// 	alert(`no encontre la categoria 2: ${ this.tabla2Comparativa[i].valor_hora }`)

      				// }

      			// }

      		}

      	}
      },

      motivoDiferencias ( ) {

      	for( const i in this.tabla2Comparativa ){

      		const { numero_horas, valor_hora, costo } = this.tabla2Comparativa[i]

      		let diferencia = this.tabla1Comparativa[i].numero_horas - numero_horas

      		let diferenciaM = parseFloat( this.tabla1Comparativa[i].costo.replace('$','').replace(',','')) - parseFloat( costo.replace('$','').replace(',','') )

      		this.tabla1Comparativa[i]['diferencia']  = this.format( diferenciaM )

      		if( numero_horas > this.tabla1Comparativa[i].numero_horas ){
      			
      			this.tabla1Comparativa[i]['motivo'] = `Menos ${ diferencia } horas de ${ valor_hora }`

      		}else if( numero_horas == this.tabla1Comparativa[i].numero_horas ){
      			
      			this.tabla1Comparativa[i]['motivo'] = 'No hay diferencia'

      		}else{

      			this.tabla1Comparativa[i]['motivo'] = `Más ${ diferencia } horas de ${ valor_hora }`

      		}
      		// 

      	}
				// this.tabla2Comparativa
      },

      format( valor ){
			  return valor.toLocaleString('es-MX', {
			    style: 'currency',
			    currency: 'MXN'
			  });
      }

    }
	}
</script>
